import React from 'react';
import Switch from "react-switch";
import { BrowserRouter as Router, Route, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png';
import avatar_1 from '../assets/images/avatar-1.jpg';
import main from '../assets/images/main.png';
import Header from '../components/header';
import URL from './URL';
import Select from 'react-select';

import $ from "jquery";


import { Component } from "react";

class dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
            modal_4: 'none',
            user_name: '',
            password: '',
            company_name: '',
            work_order: false,
            insidents: false,
            software_changes: false,
            hardware_changes: false,
            open_service_orders: '',
            order_lines: '',
            expense_types: '',
            commit_time: '',
            commit_trips: '',
            commit_expenses: '',
            all_company: [{ company_name: "", admin: { email: '' }, name: "", l_name: "", status: '' }],
            tenant_id: '',
            all_tenants: '',
            user_admin_id: '',
            all_ragex: '',
            all_work: '',
            all_insidence: '',
            all_software: '',
            all_hardware: '',
            type_: '',
            company_id: '',
            regex: '',
            project_types_drop: '',

            company_btn: "Company",
            company_management: "Conpany Management",
            direct_btn: "Direct",
            service_btn: "Service",
            table_action: "Action",
            table_company_name: "Company Name",
            table_direct: "Direct",
            table_indirect: "InDirect",
            table_rejex: "Rejex",
            table_tenant_name: "Tenant Name",
            table_type: "Type",

            commit_expenses_placeholder: "URL",
            commit_expenses_title: "Commit expenses",
            commit_time_recordings_placeholder: "URL",
            commit_time_recordings_title: "Commit time recordings",
            commit_trips_placeholder: "URL",
            commit_trips_title: "Commit trips",
            company_name_placeholder: "Company Name",
            company_name_title: "Company Name",
            direct_title: "Direct",
            expense_types_placeholder: "URL",
            expense_types_title: "Expense Types",
            hardware_changes_placeholder: "Hardware Changes",
            incidents_placeholder: "Incidents",
            open_service_order_placeholder: "URL",
            open_service_order_title: "Open service order",
            order_lines_of_open_service_orders_placeholder: "URL",
            order_lines_of_open_service_orders_title: "Order lines of open service orders",
            password_placeholder: "Password",
            password_title: "Password",
            save_btn: "Save Configration",
            software_changes_placeholder: "Software Changes",
            tenant_select: "Select Tenant",
            tenant_title: "Tenant",
            username_placeholder: "Username",
            username_title: "Username",
            work_order_placeholder: "Work Order",

            company_name_placeholder2: "Company Name",
            company_name_title2: "Company Name",
            hardware_changes_placeholder2: "Hardware Changes",
            incidents_placeholder2: "Incidents",
            password_placeholder2: "Password",
            password_title2: "Password",
            save_btn2: "Save Configration",
            service_title2: "Service Company Configration",
            software_changes_placeholder2: "Software Changes",
            tenant_select2: "Select Tenant",
            tenant_title2: "Tenant",
            username_placeholder2: "Username",
            username_title2: "Username",
            work_order_placeholder2: "Work Order",

            Rtype_type: "type",
            Rrejex_title: "Regex",
            Rrejex_placeholder: "Regex",
            add_Regex_title: "Add Regex",

            company_management: 'Company Management',
            dashboard: 'Dashboard',
            project_management: 'Project Management',
            tenants_configration: 'Tenants Configration',
            user_management: 'User Management',
        }
    }
    componentDidMount = () => {

        let data = JSON.parse(localStorage.getItem('data'))['companyManagement']
        let data2 = JSON.parse(localStorage.getItem('data'))['sideBarList']
        let data3 = JSON.parse(localStorage.getItem('data'))['directCompany']
        let data4 = JSON.parse(localStorage.getItem('data'))['serviceCompany']
        let data5 = JSON.parse(localStorage.getItem('data'))['addRegex']

        this.setState({
            dashboard: data2['dashboard'],
            tenants_configration: data2['tenants_configration'],
            company_management: data2['company_management'],
            user_management: data2['user_management'],
            project_management: data2['project_management'],

            Rtype_type: data5['type_type'],
            Rrejex_title: data5['rejex_title'],
            Rrejex_placeholder: data5['rejex_placeholder'],
            add_Regex_title: data5['add_Regex_title'],

            company_btn: data['company_btn'],
            company_management: data['company_management'],
            direct_btn: data['direct_btn'],
            service_btn: data['service_btn'],
            table_action: data['table_action'],
            table_company_name: data['table_company_name'],
            table_direct: data['table_direct'],
            table_indirect: data['table_indirect'],
            table_rejex: data['table_rejex'],
            table_tenant_name: data['table_tenant_name'],
            table_type: data['table_type'],

            commit_expenses_placeholder: data3['commit_expenses_placeholder'],
            commit_expenses_title: data3['commit_expenses_title'],
            commit_time_recordings_placeholder: data3['commit_time_recordings_placeholder'],
            commit_time_recordings_title: data3['commit_time_recordings_title'],
            commit_trips_placeholder: data3['commit_trips_placeholder'],
            commit_trips_title: data3['commit_trips_title'],
            company_name_placeholder: data3['company_name_placeholder'],
            company_name_title: data3['company_name_title'],
            direct_title: data3['direct_title'],
            expense_types_placeholder: data3['expense_types_placeholder'],
            expense_types_title: data3['expense_types_title'],
            hardware_changes_placeholder: data3['hardware_changes_placeholder'],
            incidents_placeholder: data3['incidents_placeholder'],
            open_service_order_placeholder: data3['open_service_order_placeholder'],
            open_service_order_title: data3['open_service_order_title'],
            order_lines_of_open_service_orders_placeholder: data3['order_lines_of_open_service_orders_placeholder'],
            password_placeholder: data3['password_placeholder'],
            password_title: data3['password_title'],
            save_btn: data3['expense_types_title'],
            software_changes_placeholder: data3['software_changes_placeholder'],
            tenant_select: data3['tenant_select'],
            tenant_title: data3['tenant_title'],
            username_placeholder: data3['username_placeholder'],
            username_title: data3['username_title'],
            work_order_placeholder: data3['work_order_placeholder'],

            company_name_placeholder2: data4['company_name_placeholder'],
            company_name_title2: data4['company_name_title'],
            hardware_changes_placeholder2: data4['hardware_changes_placeholder'],
            incidents_placeholder2: data4['incidents_placeholder'],
            password_placeholder2: data4['password_placeholder'],
            password_title2: data4['password_title'],
            save_btn2: data4['save_btn'],
            service_title2: data4['service_title'],
            software_changes_placeholder2: data4['software_changes_placeholder'],
            tenant_select2: data4['order_lines_of_open_service_orders_placeholde'],
            tenant_title2: data4['tenant_title'],
            username_placeholder2: data4['username_placeholder'],
            username_title2: data4['username_title'],
            work_order_placeholder2: data4['work_order_placeholder'],



        })
        $('._overlay').hide();
        if (this.props.location.state[0].id == 1) {
            this.get_companies_by_user_id(this.props.location.state[0].tenant_id)
        }
        else {
            this.get_companies()
        }

    }
    form_data = (v, id) => {

        switch (id) {
            case 1:
                this.state.user_name = v
                this.setState({
                    user_name: v
                })
                break;
            case 2:
                this.state.password = v
                this.setState({
                    password: v
                })
                break;
            case 3:

                this.state.company_name = v
                this.setState({
                    company_name: v
                })
                break;
            case 4:

                if (v == true) {

                    this.state.work_order = false
                    this.setState({
                        work_order: false
                    })

                } else {
                    this.state.work_order = true
                    this.setState({
                        work_order: true
                    })
                }
                break;
            case 5:

                if (v == true) {

                    this.state.insidents = false
                    this.setState({
                        insidents: false
                    })
                } else {
                    this.state.insidents = true
                    this.setState({
                        insidents: true
                    })
                }
                break;
            case 6:

                if (v == true) {

                    this.state.software_changes = false
                    this.setState({
                        software_changes: false
                    })
                } else {
                    this.state.software_changes = true
                    this.setState({
                        software_changes: true
                    })
                }
                break;
            case 7:

                if (v == true) {

                    this.state.hardware_changes = false
                    this.setState({
                        hardware_changes: false
                    })
                } else {
                    this.state.hardware_changes = true
                    this.setState({
                        hardware_changes: true
                    })
                }
                break;
            case 8:

                this.state.open_service_orders = v
                this.setState({
                    open_service_orders: v
                })
                break;
            case 9:

                this.state.order_lines = v
                this.setState({
                    order_lines: v
                })
                break;
            case 10:

                this.state.expense_types = v
                this.setState({
                    expense_types: v
                })
                break;
            case 11:

                this.state.commit_time = v
                this.setState({
                    commit_time: v
                })
                break;
            case 12:

                this.state.commit_trips = v
                this.setState({
                    commit_trips: v
                })
                break;
            case 13:

                this.state.commit_expenses = v
                this.setState({
                    commit_expenses: v
                })
                break;
            case 14:
                this.state.user_admin_id = v
                this.setState({
                    user_admin_id: v
                })
                break;
        }
    }
    show_modal = (id) => {
        $('._overlay').show();


        switch (id) {
            case 1:
                this.state.modal_1 = 'block';
                this.setState({
                    modal_1: 'block'
                })
                break;
            case 2:
                this.state.modal_2 = 'block';
                this.setState({
                    modal_2: 'block'
                })
                break;
            case 3:
                this.state.modal_3 = 'block';
                this.setState({
                    modal_3: 'block'
                })
                break;
            case 4:
                this.state.modal_4 = 'block';
                this.setState({
                    modal_4: 'block'
                })
                break;
        }
    }
    hide_modal = (id) => {
        $('._overlay').hide();
        switch (id) {
            case 1:
                this.state.modal_1 = 'none';
                this.setState({
                    modal_1: 'none'
                })
                break;
            case 2:
                this.state.modal_2 = 'none';
                this.setState({
                    modal_2: 'none',
                    tenant_id: '',
                    user_name: '',
                    password: '',
                    company_name: '',
                    work_order: false,
                    insidents: false,
                    software_changes: false,
                    hardware_changes: false,
                    open_service_orders: '',
                    order_lines: '',
                    expense_types: '',
                    commit_time: '',
                    commit_trips: '',
                    commit_expenses: '',

                })
                break;
            case 3:
                this.state.modal_3 = 'none';
                this.setState({
                    modal_3: 'none',
                    tenant_id: '',
                    user_name: '',
                    password: '',
                    company_name: '',
                    work_order: false,
                    insidents: false,
                    software_changes: false,
                    hardware_changes: false,
                    open_service_orders: '',
                    order_lines: '',
                    expense_types: '',
                    commit_time: '',
                    commit_trips: '',
                    commit_expenses: '',
                })
                break;
            case 4:
                this.state.modal_4 = 'none';
                this.setState({
                    modal_4: 'none',
                    regex: '',
                    project_types_drop: ''
                })
                break;
        }

    }
    hide_all_modal = () => {
        $('._overlay').hide();
        this.state.modal_1 = 'none';
        this.state.modal_2 = 'none';
        this.state.modal_3 = 'none';
        this.state.modal_4 = 'none';
        this.setState({
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
            tenant_id: '',
            user_name: '',
            password: '',
            company_name: '',
            work_order: false,
            insidents: false,
            software_changes: false,
            hardware_changes: false,
            open_service_orders: '',
            order_lines: '',
            expense_types: '',
            commit_time: '',
            commit_trips: '',
            commit_expenses: '',
            project_types: '',
            project_types_drop: '',
            regex: ''
        });

    }
    send_data = async (id) => {
        let tenant_id = this.state.user_admin_id['value']
        if (this.props.location.state[0].id == 1) {
            tenant_id = this.props.location.state[0].tenant_id
        }
        await fetch(URL.url + 'add_tenant', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "user_admin_id": tenant_id,
                "username": this.state.user_name,
                "password": this.state.password,
                "company_name": this.state.company_name,
                "work_order": this.state.work_order,
                "incidents": this.state.insidents,
                "software_changes": this.state.software_changes,
                "hardware_changes": this.state.hardware_changes,
                "service_order": this.state.open_service_orders,
                "order_line": this.state.order_lines,
                "expense_type": this.state.expense_types,
                "commit_trip": this.state.commit_time,
                "commit_time": this.state.commit_trip,
                "commit_expense": this.state.commit_expenses,
                "tenant_type": id,


            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (resjson['message'] == 'company name already exits') {
                    alert('company name already exits')
                    return false
                }
                if (this.props.location.state[0].id == 1) {
                    this.get_companies_by_user_id(this.props.location.state[0].tenant_id)
                }
                else {
                    this.get_companies()
                }
                this.hide_all_modal()
            })
            .catch(err => {

            })


    }
    get_companies = async () => {
        await fetch(URL.url + 'company_all', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_company: resjson
                })
            })
            .catch(err => {

            })
    }
    get_companies_by_user_id = async (id) => {
        await fetch(URL.url + 'company_all_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'user_admin_id': id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_company: resjson
                })
            })
            .catch(err => {

            })
    }
    get_companiey_by_id = async (id) => {
        await fetch(URL.url + 'get_tenant_by_id', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'id': id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    tenant_id: id,
                    user_name: resjson[0].username,
                    password: resjson[0].password,
                    company_name: resjson[0].campony_name,
                    work_order: resjson[0].work_order,
                    insidents: resjson[0].incidents,
                    software_changes: resjson[0].software_changes,
                    hardware_changes: resjson[0].hardware_changes,
                    open_service_orders: resjson[0].service_order,
                    order_lines: resjson[0].order_line,
                    expense_types: resjson[0].expense_type,
                    commit_time: resjson[0].commit_time,
                    commit_trips: resjson[0].commit_trip,
                    commit_expenses: resjson[0].commit_expense,
                })

                this.show_modal(3)

            })
            .catch(err => {

            })
    }
    get_companiey_by_id_direct = async (id) => {
        await fetch(URL.url + 'get_tenant_by_id', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'id': id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    tenant_id: id,
                    user_name: resjson[0].username,
                    password: resjson[0].password,
                    company_name: resjson[0].campony_name,
                    work_order: resjson[0].work_order,
                    insidents: resjson[0].incidents,
                    software_changes: resjson[0].software_changes,
                    hardware_changes: resjson[0].hardware_changes,
                    open_service_orders: resjson[0].service_order,
                    order_lines: resjson[0].order_line,
                    expense_types: resjson[0].expense_type,
                    commit_time: resjson[0].commit_time,
                    commit_trips: resjson[0].commit_trip,
                    commit_expenses: resjson[0].commit_expense,
                })

                this.show_modal(1)

            })
            .catch(err => {

            })
    }
    update_company = async (id) => {
        await fetch(URL.url + 'update_tenant', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'id': id,
                "username": this.state.user_name,
                "password": this.state.password,
                "campony_name": this.state.company_name,
                "work_order": this.state.work_order,
                "incidents": this.state.insidents,
                "software_changes": this.state.software_changes,
                "hardware_changes": this.state.hardware_changes,
                "service_order": this.state.open_service_orders,
                "order_line": this.state.order_lines,
                "expense_type": this.state.expense_types,
                "commit_trip": this.state.commit_trip,
                "commit_time": this.state.commit_time,
                "commit_expense": this.state.commit_expenses,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (this.props.location.state[0].id == 1) {
                    this.get_companies_by_user_id(this.props.location.state[0].tenant_id)
                }
                else {
                    this.get_companies()
                }
                this.hide_all_modal()
            })
            .catch(err => {

            })
    }
    get_tenant = async () => {
        await fetch(URL.url + 'all_admin', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_tenants: resjson
                })
            })
            .catch(err => {

            })
    }
    get_work = async (id) => {
        await fetch(URL.url + 'get_work', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "tenant_id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_work: resjson
                })

            })
            .catch(err => {
            })
    }
    get_incident = async (id) => {
        await fetch(URL.url + 'get_instence', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "tenant_id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_insidence: resjson
                })
            })
            .catch(err => {

            })

        await fetch(URL.url + 'get_software', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "tenant_id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_software: resjson
                })
            })
            .catch(err => {

            })


        await fetch(URL.url + 'get_hardware', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "tenant_id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_hardware: resjson
                })
            })
            .catch(err => {

            })



    }

    add_regex = async (id) => {
        if (this.state.type_ == '') {
            alert("Please Select Type");
            return false
        }
        let a = ''
        if (this.state.type_ == 1) {
            a = 'add_work'
        } else if (this.state.type_ == 0) {
            a = 'add_instence'
        } else if (this.state.type_ == 2) {
            a = 'add_software'
        } else if (this.state.type_ == 3) {
            a = 'add_hardware'
        }
        await fetch(URL.url + a, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "tenant_id": this.state.company_id, 'regex': this.state.regex }),
        })
            .then(res => res.json())
            .then((resjson) => {

                this.hide_all_modal()

            })
            .catch(err => {

            })
    }
    del_regex = async (id, val) => {

        let a = ''
        if (val == 1) {
            a = 'work_del'
        } else if (val == 2) {
            a = 'instence_del'
        } else if (val == 3) {
            a = 'software_del'
        } else if (val == 4) {
            a = 'hardware_del'
        }
        await fetch(URL.url + a, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (val == 1) {
                    this.get_work(this.state.company_id)
                } else {
                    this.get_incident(this.state.company_id)
                }
                // this.hide_all_modal()

            })
            .catch(err => {

            })
    }
    get_company_by_id = async (id) => {
        await fetch(URL.url + 'get_tenant_by_id', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "id": id }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    campony_name: resjson[0]
                })
                var data = []
                // data.push({ value: '1', label: 'Work Order' })
                if (resjson[0].work_order == '1') {
                    data.push({ value: '1', label: 'Work Order' })
                }
                if (resjson[0].incidents == '1') {
                    data.push({ value: '0', label: 'Incidents' })
                }
                if (resjson[0].software_changes == '1') {
                    data.push({ value: '2', label: 'Software Changes' })
                }
                if (resjson[0].hardware_changes == '1') {
                    data.push({ value: '3', label: 'Hardware Changes' })
                }
                this.setState({ project_types_drop: data })
            })
            .catch(err => {

            })
    }
    render() {
        return (
            <div>
                <div onClick={() => this.hide_all_modal()} className='_overlay' style={{ width: '100%', height: '100%', 'background-color': '#343a409e', 'z-index': '1000', position: 'fixed' }} >

                </div>
                <div id="wrapper">
                    <Header></Header>
                    <div className="left side-menu">
                        <div className="sidebar-inner slimscrollleft">
                            <div id="sidebar-menu">
                                <ul>
                                    <li className="has_sub">
                                        <Link to={{
                                            pathname: "dashbord",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="ti-dashboard"></i> <span>{this.state.dashboard}</span> </Link>
                                        {
                                            this.props.location.state[0].id != 1 ? (<Link to={{
                                                pathname: "tenant",
                                                state: [{ id: '', tenant_id: '' }]
                                            }}><i className="md md-settings"></i> <span>{this.state.tenants_configration} </span> </Link>)
                                                : (<div></div>)
                                        }
                                        <Link
                                            className='active'
                                            to={{
                                                pathname: "company",
                                                state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                            }}><i className="md-business"></i> <span>{this.state.company_management} </span> </Link>
                                        <Link to={{
                                            pathname: "user",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-account-box"></i> <span>{this.state.user_management}</span> </Link>
                                        <Link to={{
                                            pathname: "project",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-list"></i> <span>{this.state.project_management}</span> </Link>
                                    </li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="content-page">
                        <div className="content">
                            <div className="row title_wid">
                                <div className="col-12 main">
                                    <h5 className="title">{this.state.company_management}</h5>
                                    <div className="dropdown">
                                        <button className="btn waves-effect waves-light btn-secondary dropdown-toggle tent_cl" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.state.company_btn}
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a onClick={() => [this.show_modal(2), this.get_tenant()]} className="dropdown-item" >{this.state.direct_btn}</a>
                                            <a onClick={() => [this.show_modal(3), this.get_tenant()]} className="dropdown-item" >{this.state.service_btn}</a>
                                        </div>
                                    </div>
                                    {/*
                                    <select name="Tenant"  >
                                      <option disabled value="volvo">Tenant</option>
                                      <option value="saab">Direct</option>
                                      <option value="mercedes">Service</option>
                                    </select> */}
                                    <div className="btn-group" style={{ 'margin-top': "0.9%" }}>

                                        {/* <button onClick={()=>this.show_modal(2)}  type="button" className="btn btn-default dropdown-toggle waves-effect waves-light tent_cl" data-toggle="dropdown" aria-expanded="false">Tenant  <span className="caret" ></span></button>
                                    <div  style={{display:this.state.modal_2}}  className="dropdown-menu manual_dropdown_menu ">
                                     <a  onClick={()=>this.show_modal(3)}  className="dropdown-item " data-animation="fadein" data-plugin="custommodal"
                                     data-overlaySpeed="200" data-overlayColor="#36404a">Direct</a>
                                     <a onClick={()=>this.show_modal(3)} href="#custom-modal" className="dropdown-item" data-animation="fadein" data-plugin="custommodal"
                                     data-overlaySpeed="200" data-overlayColor="#36404a">Service</a>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                {
                                    this.state.all_company != [] ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">ID</th>
                                                                {/* <th className="txt_color">Type</th> */}
                                                                <th className="txt_color">{this.state.table_company_name}</th>
                                                                <th className="txt_color">{this.state.table_tenant_name}</th>
                                                                <th className="txt_color">{this.state.table_type}</th>
                                                                <th className="txt_color">{this.state.table_action}</th>
                                                                <th className="txt_color">{this.state.table_rejex}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.all_company.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                        <td className="td_color">{data.id}</td>
                                                                        {/* <td className="td_color">{data.tenant_type}</td> */}
                                                                        <td className="td_color">{data.campony_name}</td>
                                                                        <td className="td_color">{data.admin.email}</td>
                                                                        <td className="td_color"> {data.tenant_type == "1" ? (<span className="label label-table label-success">Indirect</span>) : (<span className="label label-table label-warning">Direct</span>)}</td>
                                                                        <td className="td_color">
                                                                            {
                                                                                data.tenant_type == 1 ?
                                                                                    (<a className="view" onClick={() => [this.get_companiey_by_id(data.id)]} data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a">
                                                                                        <i className="ion-eye eye_wid" ></i>
                                                                                    </a>) :
                                                                                    (<a className="view" onClick={() => [this.get_companiey_by_id_direct(data.id)]} data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a">
                                                                                        <i className="ion-eye eye_wid" ></i>
                                                                                    </a>)
                                                                            }
                                                                        </td>
                                                                        <td className="td_color">
                                                                            {
                                                                                data.tenant_type == 1 ?
                                                                                    (<></>) :
                                                                                    (<a className="view" onClick={() => [this.show_modal(4), this.get_work(data.id), this.get_incident(data.id), this.setState({ company_id: data.id }), this.get_company_by_id(data.id)]} data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a">
                                                                                        <i className="fa fa-cog size_img" ></i>
                                                                                    </a>)
                                                                            }
                                                                            {/* <a className="view" onClick={() => [this.show_modal(4), this.get_work(data.id), this.get_incident(data.id), this.setState({ company_id: data.id }), this.get_company_by_id(data.id)]} data-animation="fadein" data-plugin="custommodal" data-overlaySpeed="200" data-overlayColor="#36404a">
                                                                                <i className="fa fa-cog size_img" ></i>
                                                                            </a> */}

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                            </div>
                        </div>

                        <div id="direct-custom-modal" style={{ 'top': '0%', display: this.state.modal_2, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }}>
                            <button type="button" onClick={() => this.hide_modal(2)} className="close" onclick="Custombox.close();">
                                <span>&times;</span><span className="sr-only">Close</span>
                            </button>
                            <h4 className="custom-modal-title tit-mod">{this.state.direct_title}</h4>
                            <div className="custom-modal-text">
                                <div className="modal-body">
                                    <form method="" action="">
                                        {
                                            this.state.tenant_id == '' && this.props.location.state[0].id == 0 ?
                                                (<div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="field-3" className="control-label input_title">{this.state.tenant_select}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">

                                                            <div>
                                                                <Select onChange={(e) => this.form_data(e, 14)} options={this.state.all_tenants} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) :
                                                (<div className="modal-footer">
                                                </div>)
                                        }
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.username_title}</label>
                                                    <input value={this.state.user_name} onChange={(e) => this.form_data(e.target.value, 1)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.username_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.password_title}</label>
                                                    <input value={this.state.password} onChange={(e) => this.form_data(e.target.value, 2)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.password_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.company_name_title}</label>
                                                    <input value={this.state.company_name} onChange={(e) => this.form_data(e.target.value, 3)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.company_name_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.open_service_order_title}</label>
                                                    <input value={this.state.open_service_orders} onChange={(e) => this.setState({ open_service_orders: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.order_lines_of_open_service_orders_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.order_lines_of_open_service_orders_title}</label>
                                                    <input value={this.state.order_lines} onChange={(e) => this.setState({ order_lines: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.order_lines_of_open_service_orders_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.expense_types_title}</label>
                                                    <input value={this.state.expense_types} onChange={(e) => this.setState({ expense_types: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.expense_types_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_time_recordings_title}</label>
                                                    <input value={this.state.commit_time} onChange={(e) => this.setState({ commit_time: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_time_recordings_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_trips_title}</label>
                                                    <input value={this.state.commit_trip} onChange={(e) => this.setState({ commit_trip: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_trips_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_expenses_title}</label>
                                                    <input value={this.state.commit_expenses} onChange={(e) => this.setState({ commit_expenses: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_expenses_placeholder} />
                                                </div>
                                            </div>
                                        </div>





                                        <div className="form-group row">

                                            <div className="col-12" style={{ 'margin-top': "3%" }}>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.work_order ? 0 : 1} onChange={() => this.form_data(this.state.work_order, 4)} id="checkbox101" type="checkbox" />
                                                    <label for="checkbox101">
                                                        {this.state.work_order_placeholder}
                                                    </label>
                                                </div>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.insidents ? 0 : 1} onChange={() => this.form_data(this.state.insidents, 5)} id="checkbox111" type="checkbox" />
                                                    <label for="checkbox111"> {this.state.incidents_placeholder}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12" style={{}}>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.software_changes ? 0 : 1} onChange={() => this.form_data(this.state.software_changes, 6)} id="checkbox17" type="checkbox" />
                                                    <label for="checkbox17">
                                                        {this.state.software_changes_placeholder}
                                                    </label>
                                                </div>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.hardware_changes ? 0 : 1} onChange={() => this.form_data(this.state.hardware_changes, 7)} id="checkbox18" type="checkbox" />
                                                    <label for="checkbox18"> {this.state.hardware_changes_placeholder}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {
                                    this.state.tenant_id == '' ?
                                        (<div className="modal-footer">
                                            <button onClick={() => this.send_data('0')} type="button" className="btn core waves-effect waves-light">{this.state.save_btn} </button>
                                        </div>) :
                                        (<div className="modal-footer">
                                            <button onClick={() => this.update_company(this.state.tenant_id)} type="button" className="btn core waves-effect waves-light">Edit Configration </button>
                                        </div>)
                                }


                            </div>
                        </div>

                        <div id="direct-custom-modal" style={{ 'top': '0%', display: this.state.modal_3, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }}>
                            <button type="button" onClick={() => this.hide_modal(3)} className="close" onclick="Custombox.close();">
                                <span>&times;</span><span className="sr-only">Close</span>
                            </button>
                            <h4 className="custom-modal-title tit-mod">{this.state.service_title2}</h4>
                            <div className="custom-modal-text">
                                <div className="modal-body">
                                    <form method="" action="">
                                        {
                                            this.state.tenant_id == '' && this.props.location.state[0].id == 0 ?
                                                (<div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="field-3" className="control-label input_title">{this.state.tenant_select2}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">

                                                            <div>
                                                                <Select onChange={(e) => this.form_data(e, 14)} options={this.state.all_tenants} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) :
                                                (<div className="modal-footer">
                                                </div>)
                                        }
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.username_title2}</label>
                                                    <input value={this.state.user_name} onChange={(e) => this.form_data(e.target.value, 1)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.username_placeholder2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.password_title2}</label>
                                                    <input value={this.state.password} onChange={(e) => this.form_data(e.target.value, 2)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.password_placeholder2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.company_name_title2}</label>
                                                    <input value={this.state.company_name} onChange={(e) => this.form_data(e.target.value, 3)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.company_name_placeholder2} />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group row">

                                            <div className="col-12" style={{ 'margin-top': "3%" }}>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.work_order ? 0 : 1} onChange={() => this.form_data(this.state.work_order, 4)} id="checkbox101" type="checkbox" />
                                                    <label for="checkbox101">
                                                        {this.state.work_order_placeholder2}
                                                    </label>
                                                </div>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.insidents ? 0 : 1} onChange={() => this.form_data(this.state.insidents, 5)} id="checkbox111" type="checkbox" />
                                                    <label for="checkbox111"> {this.state.incidents_placeholder2}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12" style={{}}>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.software_changes ? 0 : 1} onChange={() => this.form_data(this.state.software_changes, 6)} id="checkbox17" type="checkbox" />
                                                    <label for="checkbox17">
                                                        {this.state.software_changes_placeholder2}
                                                    </label>
                                                </div>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.hardware_changes ? 0 : 1} onChange={() => this.form_data(this.state.hardware_changes, 7)} id="checkbox18" type="checkbox" />
                                                    <label for="checkbox18"> {this.state.hardware_changes_placeholder2}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                {
                                    this.state.tenant_id == '' ?
                                        (<div className="modal-footer">
                                            <button onClick={() => this.send_data('1')} type="button" className="btn core waves-effect waves-light">{this.state.save_btn2} </button>
                                        </div>) :
                                        (<div className="modal-footer">
                                            <button onClick={() => this.update_company(this.state.tenant_id)} type="button" className="btn core waves-effect waves-light">Edit Configration </button>
                                        </div>)
                                }


                            </div>
                        </div>
                        <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_1, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                            <button type="button" onClick={() => this.hide_modal(1)} className="close" onclick="Custombox.close();">
                                <span>&times;</span><span className="sr-only">Close</span>
                            </button>
                            <h4 className="custom-modal-title tit-mod">{this.state.direct_title}</h4>
                            <div className="custom-modal-text">
                                <div className="modal-body">
                                    <form method="" action="">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.username_title}</label>
                                                    <input value={this.state.user_name} onChange={(e) => this.form_data(e.target.value, 1)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.username_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.password_title}</label>
                                                    <input value={this.state.password} onChange={(e) => this.form_data(e.target.value, 2)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.password_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.company_name_title}</label>
                                                    <input value={this.state.company_name} onChange={(e) => this.form_data(e.target.value, 3)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.company_name_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.open_service_order_title}</label>
                                                    <input value={this.state.open_service_orders} onChange={(e) => this.setState({ open_service_orders: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.open_service_order_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.order_lines_of_open_service_orders_title}</label>
                                                    <input value={this.state.order_lines} onChange={(e) => this.setState({ order_lines: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.order_lines_of_open_service_orders_placeholde} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.expense_types_title}</label>
                                                    <input value={this.state.expense_types} onChange={(e) => this.setState({ expense_types: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.expense_types_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_time_recordings_title}</label>
                                                    <input value={this.state.commit_time} onChange={(e) => this.setState({ commit_time: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_time_recordings_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_trips_title}</label>
                                                    <input value={this.state.commit_trip} onChange={(e) => this.setState({ commit_trip: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_trips_placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="field-3" className="control-label input_title">{this.state.commit_expenses_title}</label>
                                                    <input value={this.state.commit_expenses} onChange={(e) => this.setState({ commit_expenses: e.target.value })} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.commit_expenses_placeholder} />
                                                </div>
                                            </div>
                                        </div>





                                        <div className="form-group row">

                                            <div className="col-12" style={{ 'margin-top': "3%" }}>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.work_order ? 0 : 1} onChange={() => this.form_data(this.state.work_order, 4)} id="checkbox101" type="checkbox" />
                                                    <label for="checkbox101">
                                                        {this.state.work_order_placeholder}
                                                    </label>
                                                </div>
                                                <div className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.insidents ? 0 : 1} onChange={() => this.form_data(this.state.insidents, 5)} id="checkbox111" type="checkbox" />
                                                    <label for="checkbox111"> {this.state.incidents_placeholder}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12" style={{}}>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.software_changes ? 0 : 1} onChange={() => this.form_data(this.state.software_changes, 6)} id="checkbox17" type="checkbox" />
                                                    <label for="checkbox17">
                                                        {this.state.software_changes_placeholder}
                                                    </label>
                                                </div>
                                                <div style={{ 'margin-right': '19%' }} className="checkbox select_tpye">
                                                    <input checked={'0' == this.state.hardware_changes ? 0 : 1} onChange={() => this.form_data(this.state.hardware_changes, 7)} id="checkbox18" type="checkbox" />
                                                    <label for="checkbox18"> {this.state.hardware_changes_placeholder}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {
                                    this.state.tenant_id == '' ?
                                        (<div className="modal-footer">
                                            <button onClick={() => this.send_data('1')} type="button" className="btn core waves-effect waves-light">{this.state.save_btn} </button>
                                        </div>) :
                                        (<div className="modal-footer">
                                            <button onClick={() => this.update_company(this.state.tenant_id)} type="button" className="btn core waves-effect waves-light">Edit Configration </button>
                                        </div>)
                                }


                            </div>
                        </div>
                        <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_4, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                            <button type="button" onClick={() => this.hide_modal(4)} className="close" onclick="Custombox.close();">
                                <span>&times;</span><span className="sr-only">Close</span>
                            </button>
                            <h4 className="custom-modal-title tit-mod">{this.state.add_Regex_title}</h4>
                            <div className="custom-modal-text">
                                {
                                    this.state.all_work.length != 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">work_order</th>
                                                                <th className="txt_color">Regex</th>
                                                                <th className="txt_color">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.all_work.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                        <td className="td_color">{index + 1}</td>
                                                                        <td className="td_color">{data.work_order}</td>
                                                                        <td className="td_color">
                                                                            {/* <a onClick={() => [this.show_modal(3, data.value), this.get_single_project(data.value), this.get_assigh_user(data.value)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a> */}
                                                                            <a onClick={() => [this.del_regex(data.id, 1)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-trash size_img"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                                {
                                    this.state.all_insidence.length != 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">Incidents</th>
                                                                <th className="txt_color">Regex</th>
                                                                <th className="txt_color">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.all_insidence.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                        <td className="td_color">{index + 1}</td>
                                                                        <td className="td_color">{data.instance}</td>
                                                                        <td className="td_color">
                                                                            {/* <a onClick={() => [this.show_modal(3, data.value), this.get_single_project(data.value), this.get_assigh_user(data.value)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a> */}
                                                                            <a onClick={() => [this.del_regex(data.id, 2)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-trash size_img"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                                {
                                    this.state.all_software.length != 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">Software Changes</th>
                                                                <th className="txt_color">Regex</th>

                                                                <th className="txt_color">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.all_software.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                        <td className="td_color">{index + 1}</td>
                                                                        <td className="td_color">{data.software}</td>
                                                                        <td className="td_color">
                                                                            {/* <a onClick={() => [this.show_modal(3, data.value), this.get_single_project(data.value), this.get_assigh_user(data.value)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a> */}
                                                                            <a onClick={() => [this.del_regex(data.id, 3)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-trash size_img"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                                {
                                    this.state.all_hardware.length != 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">Hardware Changes</th>
                                                                <th className="txt_color">Regex</th>

                                                                <th className="txt_color">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                this.state.all_hardware.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                        <td className="td_color">{index + 1}</td>
                                                                        <td className="td_color">{data.hardware}</td>
                                                                        <td className="td_color">
                                                                            {/* <a onClick={() => [this.show_modal(3, data.value), this.get_single_project(data.value), this.get_assigh_user(data.value)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a> */}
                                                                            <a onClick={() => [this.del_regex(data.id, 4)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-trash size_img"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                                <div className="custom-modal-text_2">
                                    <form method="" action="">
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.Rtype_type}</label></div>
                                                        <div className="col-md-9" >

                                                            {
                                                                this.state.assign_users != '' ?
                                                                    (<Select
                                                                        // closeMenuOnSelect={false}
                                                                        // components={animatedComponents}
                                                                        // defaultValue={[this.state.assign_users[0]]}
                                                                        // defaultValue={this.state.assign_users}
                                                                        // isClearable={true}
                                                                        // defaultValue={this.state.assign_users}
                                                                        // isMulti
                                                                        name="colors"
                                                                        options={this.state.project_types_drop}
                                                                        // options={colourOptions}
                                                                        onChange={(e) => this.setState({ type_: e['value'] })}
                                                                        // className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                    />) :
                                                                    (<div>
                                                                        <Select
                                                                            // isMulti
                                                                            options={this.state.all_tenants}
                                                                            onChange={(e) => this.setState({ type_: e['value'] })}

                                                                        />
                                                                    </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.Rrejex_title}</label></div>
                                                        <div className="col-md-9" ><input onChange={(e) => this.setState({ regex: e.target.value })} type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.regex} id="field-3" placeholder={this.state.Rrejex_placeholder} /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={() => [this.add_regex()]} type="button" className="btn core waves-effect waves-light">Add Regex</button>
                                        </div>
                                    </form>
                                </div>

                            </div  >

                        </div>
                    </div>
                    <script src="assets/js/popper.min.js"></script>
                    <script src="assets/js/bootstrap.min.js"></script>
                    <script src="assets/js/detect.js"></script>
                    <script src="assets/js/fastclick.js"></script>
                    <script src="assets/js/jquery.slimscroll.js"></script>
                    <script src="assets/js/jquery.blockUI.js"></script>
                    <script src="assets/js/waves.js"></script>
                    <script src="assets/js/wow.min.js"></script>
                    <script src="assets/js/jquery.nicescroll.js"></script>
                    <script src="assets/js/jquery.scrollTo.min.js"></script>
                    <script src="../plugins/peity/jquery.peity.min.js"></script>
                    <script src="../plugins/waypoints/lib/jquery.waypoints.min.js"></script>
                    <script src="../plugins/counterup/jquery.counterup.min.js"></script>
                    <script src="../plugins/morris/morris.min.js"></script>
                    <script src="../plugins/raphael/raphael-min.js"></script>
                    <script src="../plugins/jquery-knob/jquery.knob.js"></script>
                    <script src="assets/pages/jquery.dashboard.js"></script>
                    <script src="assets/js/jquery.core.js"></script>
                    <script src="assets/js/jquery.app.js"></script>
                </div>

            </div>
        );
    }


}

export default dashboard;


