
const iState ={
	name:"Ali",
	G_mainData:[],
	G_UniData:[],
	G_RnkData:0,
	G_AcrData:0,
	G_d_flag:0,
	G_p_flag:0,

}
const reducer = (state=iState, action) => {
	if(action.type==='CHANGE_G_MAIN_DATA'){
		return{
			...state,
			G_mainData:action.payload
		}
	}
	if(action.type==='CHANGE_UNI_DATA'){
		return{
			...state,
			G_UniData:action.payload
		}
	}
	if(action.type==='CHANGE_RNK_DATA'){
		return{
			...state,
			G_RnkData:action.payload
		}
	}
	if(action.type==='CHANGE_ACR_DATA'){
		return{
			...state,
			G_AcrData:action.payload
		}
	}
	if(action.type==='CHANGE_P_Flag'){
		return{
			...state,
			G_p_flag:action.payload
		}
	}
	if(action.type==='CHANGE_D_Flag'){
		return{
			...state,
			G_d_flag:action.payload
		}
	}
	return state;
}

export default reducer;