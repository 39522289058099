import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect, Link,HashRouter, BrowserRouter } from "react-router-dom";
import Login from './screens/Login';
import dashbord from './screens/dashbord';
import user from './screens/user';
import tenant from './screens/tenant';
import project from './screens/project';
import company from './screens/company';




class App extends Component {
  render() {
    console.log = console.warn = console.error = () => {};
    return (
      <BrowserRouter>
          <Switch>
            <Route exact path = "/" component = { Login } />
            <Route exact path = "/dashbord" component = { dashbord } />
            <Route exact path = "/tenant" component = { tenant } />
            <Route exact path = "/user" component = { user } />
            <Route exact path = "/project" component = { project } />
            <Route exact path = "/company" component = { company } />
         </Switch>
        </BrowserRouter>
    );
  }
}

export default App;
