import React from 'react';
import Switch from "react-switch";
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png'
import avatar_1 from '../assets/images/avatar-1.jpg';
import main from '../assets/images/main.png';
import Header from '../components/header';
import URL from './URL';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios, { post } from 'axios';

import $ from "jquery";

import Schadual_form from '../components/Schadual_form'


import { BrowserRouter as Router, Route, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";


import { Component } from "react";
const animatedComponents = makeAnimated();
const colourOptions = [{ value: '120', label: 'ali@gmail.com' }, { value: '122', label: 'hushain@gmail.com' }, { value: '123', label: 'aun@gmail.com' }, { value: '124', label: 'mubashir@gmail.com' }]

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_projects: [{ id: "", project_no: "asas", company_name: "asdasd", task: "dasdasdas", address: '' }],
            checked: false,
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
            modal_4: 'none',
            company: '',
            task: '',
            project_no: '',
            project_type: '',
            seller_name: '',
            seller_country_code: '',
            customer_name: '',
            customer_address: '',
            customer_address_2: '',
            customer_zip_code: '',
            customer_city: '',
            customer_country_code: '',
            email: '',
            user_id: '',
            tanant: '',
            all_tenants: '',
            file: '',
            file_name: '',
            user_admin: false,
            admin_type: '',
            project_id: '',
            assign_users: '',
            plan_date: '',
            start_time: '',
            end_time: '',
            add_user: '',

            title: 'Welcome to Admin',
            sync_btn: 'Sync Data',
            table_project_no: 'Project No',
            table_company_name: 'Company Name',
            table_task_dIsc: 'Task DIsc',
            table_address: 'Addresss',
            table_action: 'Action',

            title: 'Project',
            project_title: 'Projects',
            company_name_title: 'Company Name',
            project_no: 'Project No',
            task_name: 'Task Name',
            project_type: 'Project Type',
            selling_customer_name: 'Selling Customer Name',
            selling_customer_country_code: 'Selling Customer Country Code',
            customer_name_2: 'Customer Name',
            customer_address_2: 'Customer Address',
            customer_address2_2: 'Customer Address 2',
            customer_zip_code_2: 'Customer Zip Code',
            customer_city_2: 'Customer City',
            customer_country_code_2: 'Customer Country Code',


            title3: 'Assignee',
            schadual_id3: 'Schadual id',
            table_project_no3: 'Project No',
            table_action3: 'Action',
            assign_resources3: 'Assign Resources',
            assign_resources_select3: 'Select',
            date3: 'Date',
            start_time3: 'Start Time',
            end_time3: 'End Time',
            assign_user_btn3: 'Assign User',

            company_management: 'Company Management',
            dashboard: 'Dashboard',
            project_management: 'Project Management',
            tenants_configration: 'Tenants Configration',
            user_management: 'User Management',
            schedule_form: [],
            schedule_form_no: 0,
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }
    componentDidMount = () => {
        this.append_schedule_form()
        let data22 = JSON.parse(localStorage.getItem('data'))['sideBarList']
        let data = JSON.parse(localStorage.getItem('data'))['projectManagement']
        let data2 = JSON.parse(localStorage.getItem('data'))['viewProject']
        let data3 = JSON.parse(localStorage.getItem('data'))['assigneeProject']
        this.setState({
            dashboard: data22['dashboard'],
            tenants_configration: data22['tenants_configration'],
            company_management: data22['company_management'],
            user_management: data22['user_management'],
            project_management: data22['project_management'],
            title: data['title'],
            sync_btn: data['sync_btn'],
            table_project_no: data['table_project_no'],
            table_company_name: data['table_company_name'],
            table_task_dIsc: data['table_task_dIsc'],
            table_address: data['table_address'],
            table_action: data['table_action'],


            title: data2['title'],
            project_title: data2['project_title'],
            company_name_title: data2['company_name_title'],
            project_no: data2['project_no'],
            task_name: data2['task_name'],
            project_type: data2['project_type'],
            selling_customer_name: data2['selling_customer_name'],
            selling_customer_country_code: data2['selling_customer_country_code'],
            customer_name_2: data2['customer_name'],
            customer_address_23: data2['customer_address'],
            customer_address2_2: data2['customer_address2'],
            customer_zip_code_2: data2['customer_zip_code'],
            customer_city_2: data2['customer_city'],
            customer_country_code_2: data2['customer_country_code'],

            title3: data3['title'],
            schadual_id3:data3['schedule_id'],
            table_project_no3: data3['table_project_no'],
            table_action3: data3['table_action'],
            assign_resources3: data3['assign_resources'],
            assign_resources_select3: data3['assign_resources_select'],
            date3: data3['date'],
            start_time3: data3['start_time'],
            end_time3: data3['end_time'],
            assign_user_btn3: data3['assign_user_btn'],
        })
        if (this.props.location.state[0].id == 1) {
            this.project_tenant_id()
        } else {
            this.project()
        }
        // this.state.admin_type = this.props.location.state[0].id
        // this.setState({
        //     admin_type: this.props.location.state[0].id
        // })
        $('._overlay').hide();
    }
    append_schedule_form = () => {
        console.log(24234234)
        this.state.schedule_form.push(<Schadual_form />);
        this.setState({
            schedule_form: this.state.schedule_form,
        });

        // this.state.schedule_form_no++
        console.log(this.state.schedule_form);
    }
    form_data2 = (v, id) => {

        switch (id) {
            case 1:
                this.state.plan_date = v
                this.setState({
                    plan_date: v
                })
                break;
            case 2:
                this.state.start_time = v
                this.setState({
                    start_time: v
                })
                break;
            case 3:
                this.state.end_time = v
                this.setState({
                    end_time: v
                })

                break;
            case 4:
                this.state.add_user = v
                this.setState({
                    add_user: v
                })

                break;
        }
    }
    show_modal = (id, project_id = '') => {
        $('._overlay').show();

        switch (id) {
            case 1:

                this.state.modal_1 = 'block';
                this.setState({
                    modal_1: 'block'
                })
                break;
            case 2:
                this.state.modal_2 = 'block';
                this.setState({
                    modal_2: 'block'
                })
                break;
            case 3:
                this.state.modal_3 = 'block';
                this.get_user_by_tenant();
                this.setState({
                    modal_3: 'block',
                    project_id: project_id
                })
                break;
            case 4:
                this.state.modal_4 = 'block';
                this.get_user_by_tenant(project_id);
                this.setState({
                    modal_4: 'block',
                    project_id: project_id
                })
                break;
        }
    }
    hide_modal = (id) => {
        $('._overlay').hide();
        switch (id) {
            case 1:
                this.state.modal_1 = 'none';
                this.setState({
                    modal_1: 'none'
                })
                break;
            case 2:
                this.state.modal_2 = 'none';
                this.setState({
                    modal_2: 'none'
                })
                break;
            case 3:
                this.state.modal_3 = 'none';
                this.setState({
                    modal_3: 'none'
                })
                break;
            case 4:
                this.state.modal_4 = 'none';
                this.setState({
                    modal_4: 'none'
                })
                break;
        }

    }
    hide_all_modal = (id) => {
        $('._overlay').hide();
        this.state.modal_1 = 'none';
        this.state.modal_2 = 'none';
        this.state.modal_3 = 'none';
        this.state.modal_4 = 'none';
        this.setState({
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
            modal_4: 'none',
        });
    }
    form_data = (v, id) => {

        switch (id) {
            case 1:
                this.state.salutation = v
                this.setState({
                    salutation: v
                })
                break;
            case 2:
                this.state.first_n = v
                this.setState({
                    first_n: v
                })
                break;
            case 3:
                this.state.last_n = v
                this.setState({
                    last_n: v
                })
                break;
            case 4:
                this.state.email = v
                this.setState({
                    email: v
                })
                break;
            case 5:
                this.state.tanant = v
                this.setState({
                    tanant: v
                })
                break;
            case 6:
                if (v == true) {

                    this.state.user_admin = false
                    this.setState({
                        user_admin: false
                    })
                } else {
                    this.state.user_admin = true
                    this.setState({
                        user_admin: true
                    })
                }
                break;

        }
    }
    send_data = async () => {
        if (this.state.salutation == '' || this.state.first_n == '' || this.state.last_n == '' || this.state.email == '' || this.state.tanant['value'] == '') {
            alert('Fill all the fielda')
            return false
        }
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // return re.test(email);
        if (!re.test(this.state.email)) {
            alert('Email is not correct')
            return false
        }
        let tenant_id = this.state.tanant['value']
        if (this.props.location.state[0].id == 1) {
            tenant_id = this.props.location.state[0].tenant_id
        }
        await fetch(URL.url + 'register_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "sol": this.state.salutation,
                "name": this.state.first_n,
                "l_name": this.state.last_n,
                "email": this.state.email,
                'tenant_id': tenant_id,
                "user_type": this.state.user_admin,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                alert('User register successfully')
            })
            .catch(err => {
            })

    }
    get_user_by_tenant = async (id = '') => {

        await fetch(URL.url + 'all_user_by_assign', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // 'tenant_id': this.props.location.state[0].tenant_id,
                'project_id': id

            }),
        })
            .then(res => res.json())
            .then((resjson) => {

                let array = []
                for (let i = 0; i < resjson.length; i++) {
                  
                        let temp = { value: resjson[i].id, label: resjson[i].email }
                        array.push(temp,)
                   

                }
                this.state.all_tenants = array
                this.setState({
                    all_tenants: this.state.all_tenants
                })


            })
            .catch(err => {
            })


    }
    change_status = async (status, id) => {
        let a = true
        if (status == 1) {
            a = '0'
        } else {
            a = '1'
        }
        await fetch(URL.url + 'update_user_status', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "status": a,
                "id": id,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.get_user();

            })
            .catch(err => {
            })
    }
    project = async () => {
        await fetch(URL.url + 'all_project', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                // this.setState({
                //     all_projects: resjson
                // })
                let array = []
                for (let i = 0; i < resjson.length; i++) {
                    let temp = { id: resjson[i].project_id, Project_no: resjson[i].projectmeta[4].meta_value, company_name: resjson[i].company_name, task: resjson[i].projectmeta[0].meta_value, address: resjson[i].projectmeta[6].meta_value }
                    array.push(temp,)

                }
                this.state.all_projects = array
                this.setState({
                    all_projects: this.state.all_projects
                })
            })
            .catch(err => {
            })
    }
    project_tenant_id = async () => {
        await fetch(URL.url + 'all_project_tenant', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'user_admin_id': this.props.location.state[0].tenant_id }),
        })
            .then(res => res.json())
            .then((resjson) => {



                // this.setState({
                //     all_projects: resjson
                // })
                let array = []
                for (let i = 0; i < resjson.length; i++) {
                    let temp = { id: resjson[i].project_id, Project_no: resjson[i].projectmeta[4].meta_value, company_name: resjson[i].company_name, task: resjson[i].projectmeta[0].meta_value, address: resjson[i].projectmeta[2].meta_value }
                    array.push(temp,)

                }
                this.state.all_projects = array
                this.setState({
                    all_projects: this.state.all_projects
                })
            })
            .catch(err => {
            })
    }
    get_user = async () => {
        await fetch(URL.url + 'project_tenant_id', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'tenant_id': this.props.location.state[0].tenant_id
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    all_projects: resjson//[{ 'id': "", 'project_no': "asas", 'company_name': "asdasd", 'task': "dasdasdas", 'address': '' }]
                })
                let array = []
                for (let i = 0; i < resjson.length; i++) {
                    let temp = { id: resjson[i].project_id, Project_no: resjson[i].projectmeta[4].meta_value, company_name: resjson[i].company_name, task: resjson[i].projectmeta[0].meta_value, address: resjson[i].projectmeta[8].meta_value }
                    array.push(temp,)

                }
                this.state.all_projects = array
                this.setState({
                    all_projects: this.state.all_projects
                })
            })
            .catch(err => {
            })
    }
    get_single_project = async (id) => {

        await fetch(URL.url + 'project_by_id', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'project_id': id
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    company: resjson[0].company_name,
                    task: resjson[0].projectmeta[0].meta_value,
                    project_type: resjson[0].projectmeta[3].meta_value,
                    project_no: resjson[0].projectmeta[4].meta_value,
                    seller_name: resjson[0].projectmeta[5].meta_value,
                    seller_country_code: resjson[0].projectmeta[6].meta_value,
                    customer_name: resjson[0].projectmeta[7].meta_value,
                    customer_address: resjson[0].projectmeta[8].meta_value,
                    customer_address_2: resjson[0].projectmeta[9].meta_value,
                    customer_zip_code: resjson[0].projectmeta[10].meta_value,
                    customer_city: resjson[0].projectmeta[11].meta_value,
                    customer_country_code: resjson[0].projectmeta[12].meta_value,

                })
            })
            .catch(err => {

            })
    }
    assigh_projects = async () => {
        for (let index = 0; index < this.state.tanant.length; index++) {
            await fetch(URL.url + 'assign_project', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'project_id': this.state.project_id,
                    "user_id": this.state.tanant[index]['value'],
                    "email": this.state.tanant[index]['label'],
                }),
            })
                .then(res => res.json())
                .then((resjson) => {
                    // this.hide_all_modal();
                    // this.get_user();

                })
                .catch(err => {

                })

        }

    }
    get_assigh_user = async (id) => {

        await fetch(URL.url + 'get_assign_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'project_id': id,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    assign_users: resjson,
                    tanant: resjson
                })


            })
            .catch(err => {

            })


    }
    import_user = async () => {


    }
    onFormSubmit(e) {
        e.preventDefault()
        this.fileUpload(this.state.file);
    }
    onChange(e) {
        this.setState({
            file_name: e.target.files[0].name
        })
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                file: e.target.result
            })
        };
        reader.readAsDataURL(file);
    }
    fileUpload = async (image) => {

        var res = this.state.file.replace("data:application/octet-stream;base64,", "")
        await fetch(URL.url + 'import', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "file": res,
                "file_name": this.state.file_name,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.hide_all_modal();
                this.get_user();

            })
            .catch(err => {

            })

    }
    del_assinees = async (id,schadual_id) => {

        await fetch(URL.url + 'assign_delete', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'project_id': this.state.project_id,
                'schadual_id': schadual_id,
                'user_id': id
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.hide_all_modal();
                this.assigh_projects();

            })
            .catch(err => {

            })

    }
    edit_project = async () => {

        await fetch(URL.url + 'update_project', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'project_id': this.state.project_id,
                'campony_name': this.state.company,
                'task_name': this.state.task,
                'project_type': this.state.project_type,
                'project_no': this.state.project_no,
                'sell_customer_name': this.state.seller_name,
                'sell_customer_cuntry_code': this.state.seller_country_code,
                'delivery_customer_name': this.state.customer_name,
                'delivery_customer_address': this.state.customer_address,
                'delivery_customer_address2': this.state.customer_address_2,
                'delivery_customer_zip_code': this.state.customer_zip_code,
                'delivery_customer_city': this.state.customer_city,
                'delivery_customer_cuntry_code': this.state.customer_country_code,



            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.hide_all_modal();
                // this.get_user();

            })
            .catch(err => {

            })

    }
    addign_uesr = async () => {
        await fetch(URL.url + 'assign_project', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'project_id': this.state.project_id,
                'user_id': this.state.add_user['value'],
                'email': this.state.add_user['label'],
                'start_date': this.state.plan_date,
                'start_time': this.state.start_time,
                'end_time': this.state.end_time,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.hide_all_modal();
                // this.get_user();

            })
            .catch(err => {

            })

    }
    sync_data = async () => {
        this.sync_data2()
        let url = "svnc_project"
        if (this.props.location.state[0].id == 0) {
            let url = "sync_all_project"
        }
        await fetch(URL.url + url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_admin_id': this.props.location.state[0].tenant_id

            }),
        })
            .then(res => res.json())
            .then((resjson) => {

                if (this.props.location.state[0].id == 1) {
                    this.project_tenant_id()
                } else {
                    this.project()
                }
                // this.hide_all_modal();
                // this.get_user();
            })
            .catch(err => {
            })
    }
    sync_data2 = async () => {

        await fetch(URL.url + "multi_expence", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_admin_id': this.props.location.state[0].tenant_id

            }),
        })
            .then(res => res.json())
            .then((resjson) => {

                // this.hide_all_modal();
                // this.get_user();
            })
            .catch(err => {
            })
    }
    render() {
        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]
        return (
            <div>
                <div onClick={() => this.hide_all_modal()} className='_overlay' style={{ width: '100%', height: '100%', 'background-color': '#343a409e', 'z-index': '1000', position: 'absolute' }} > </div>
                <div id="wrapper">
                    <Header></Header>
                    <div className="left side-menu">
                        <div className="sidebar-inner slimscrollleft">

                            <div id="sidebar-menu">
                                <ul>
                                    <li className="has_sub">
                                        <Link to={{
                                            pathname: "dashbord",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="ti-dashboard"></i> <span>{this.state.dashboard}</span> </Link>
                                        {
                                            this.props.location.state[0].id != 1 ? (<Link to={{
                                                pathname: "tenant",
                                                state: [{ id: '', tenant_id: '' }]
                                            }}><i className="md md-settings"></i> <span>{this.state.tenants_configration}</span> </Link>)
                                                : (<div></div>)
                                        }
                                        <Link to={{
                                            pathname: "company",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-business"></i> <span>{this.state.company_management}</span> </Link>
                                        <Link to={{
                                            pathname: "user",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-account-box"></i> <span>{this.state.user_management}</span> </Link>
                                        <Link
                                            className='active'
                                            to={{
                                                pathname: "project",
                                                state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                            }}><i className="md-list"></i> <span>{this.state.project_management}</span> </Link>
                                    </li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="content-page">
                        <div className="content">
                            <div className="row title_wid">
                                <div className="col-12 main">
                                    {/* <h5 className="title">User Managment</h5> */}
                                    
                                    {
                                        this.props.location.state[0].id != '' ? (
                                            <a onClick={() => this.sync_data()} className="btn btn-primary waves-effect waves-light ad_user" data-animation="fadein" data-plugin="custommodal"
                                                    data-overlaySpeed="200" data-overlayColor="#36404a">{this.state.sync_btn}</a>
                                           
                                        ) : (
                                            <></>
                                            )
                                    }
                                    {/* <a onClick={() => this.show_modal(2)} className="btn  waves-effect waves-light btn_text" data-animation="fadein" data-plugin="custommodal"
                                        data-overlaySpeed="200" data-overlayColor="#36404a">Import User</a> */}
                                </div>
                            </div>
                            <div className="container-fluid">
                                {
                                    this.state.all_projects != [] ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                <th className="txt_color">#</th>
                                                                <th className="txt_color">{this.state.table_project_no}</th>
                                                                <th className="txt_color">{this.state.table_company_name}</th>
                                                                <th className="txt_color">{this.state.table_task_dIsc}</th>
                                                                <th className="txt_color">{this.state.table_address}</th>
                                                                <th className="txt_color">{this.state.table_action}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.all_projects.map((data, index) => (
                                                                    <tr>
                                                                        {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td>
                                                                        */}
                                                                        <td className="td_color">{index + 1}</td>
                                                                        <td className="td_color">{data.Project_no}</td>
                                                                        <td className="td_color">{data.company_name}</td>
                                                                        <td className="td_color">{data.task}</td>
                                                                        <td className="td_color">{data.address}</td>
                                                                        <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3, data.id), this.get_single_project(data.id), this.get_assigh_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a>
                                                                            <a onClick={() => [this.show_modal(4, data.id), this.get_single_project(data.id), this.get_assigh_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-cog size_img"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_1, position: 'absolute', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(1)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                <h4 className="custom-modal-title tit-mod">Add User</h4>
                                <div className="custom-modal-text">
                                    <form method="" action="">
                                        <div className="modal-body">
                                            {
                                                this.props.location.state[0].id != 1 ?
                                                    (<div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label for="field-3" className="control-label input_title">Tenant</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group">

                                                                <div>
                                                                    <Select onChange={(e) => this.form_data(e, 5)} options={this.state.all_tenants} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>) : (<div></div>)
                                            }


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">Soutation</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 1)} className="form-control line_inp" id="field-3" placeholder="Mr" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label id={121} for="field-3" className="control-label input_title">First Name</label>
                                                        <input onChange={(e) => this.form_data(e.target.value, 2)} type="text" className="form-control line_inp" id="field-3" placeholder="First Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">Last Name</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 3)} className="form-control line_inp" id="field-3" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">Email</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 4)} className="form-control line_inp" id="field-3" placeholder="Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12" style={{}}>
                                                    <div style={{ 'margin-right': '19%', 'margin-left': '12PX' }} className="checkbox select_tpye">
                                                        <input onChange={() => this.form_data(this.state.user_admin, 6)} id="checkbox17" type="checkbox" />
                                                        <label style={{ color: '#9a9a9a' }} className="control-label input_title" for="checkbox17">
                                                            User Admin ?
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={() => this.send_data()} type="button" className="btn core waves-effect waves-light">Save User</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_2, position: 'absolute', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(2)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                <h4 className="custom-modal-title tit-mod">Import User</h4>
                                <div className="custom-modal-text">
                                    <div className="modal-body">
                                        <form onSubmit={this.onFormSubmit}>
                                            <div className="form-group m-b-0">
                                                <input className="filestyle" data-buttonname="btn-primary" type="file" onChange={this.onChange} />
                                            </div>
                                            <div className="modal-footer">
                                                <button onChange={this.fileUpload} type="submit" className="btn btn-info waves-effect waves-light">Import</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_3, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(3)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                <h4 className="custom-modal-title tit-mod">{this.state.title}</h4>
                                <div className="custom-modal-text">
                                    <form method="" action="">
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" style={{ fontWeight: 'bold' }} className="control-label input_title">{this.state.company_name_title}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.company} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.project_no}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.project_no} id="field-3" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.task_name}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.task} id="field-3" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.project_type}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.project_type} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.selling_customer_name}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.seller_name} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.selling_customer_country_code}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.seller_country_code} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_name_2}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_name} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_address_23}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_address} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_address2_2}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_address_2} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_zip_code_2}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_zip_code} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_city_2}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_city} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.customer_country_code_2}</label></div>
                                                        <div className="col-md-9" ><input type="text" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.customer_country_code} id="field-3" placeholder="Mr" /></div>
                                                        {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">Assign Resources</label></div>
                                                        <div className="col-md-9" >

                                                            {
                                                                this.state.assign_users != '' ?
                                                                    (<Select
                                                                        // closeMenuOnSelect={false}
                                                                        // components={animatedComponents}
                                                                        // defaultValue={[this.state.assign_users[0]]}
                                                                        // defaultValue={this.state.assign_users}
                                                                        // isClearable={this.state.all_tenants}
                                                                        defaultValue={this.state.assign_users}
                                                                        isMulti
                                                                        name="colors"
                                                                        options={this.state.all_tenants}
                                                                        // options={colourOptions}
                                                                        onChange={(e) => this.form_data(e, 5)}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                    />) :
                                                                    (<div>
                                                                        <Select
                                                                            isMulti
                                                                            options={this.state.all_tenants}
                                                                            onChange={(e) => this.form_data(e, 5)}

                                                                        />
                                                                    </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}




                                        </div>
                                        {/* <div className="modal-footer">
                                            <button onClick={() => [this.del_assinees(), this.edit_project()]} type="button" className="btn core waves-effect waves-light">Submit</button>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_4, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(4)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                <h4 className="custom-modal-title tit-mod">{this.state.title3}</h4>
                                <div className="custom-modal-text">
                                    {
                                        this.state.assign_users != [] ? (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-box table-responsive">
                                                        <table id="datatable" className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th><i className="fa fa-cog size_img" /></th> */}
                                                                    <th className="txt_color">#</th>
                                                                    <th className="txt_color">{this.state.schadual_id3}</th>
                                                                    <th className="txt_color">{this.state.table_project_no3}</th>

                                                                    <th className="txt_color">{this.state.table_action3}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    this.state.assign_users.map((data, index) => (
                                                                        <tr>
                                                                            {/* <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td> */}
                                                                            <td className="td_color">{index + 1}</td>
                                                                            <td className="td_color">{data.id}</td>
                                                                            <td className="td_color">{data.label}</td>
                                                                            <td className="td_color">
                                                                                {/* <a onClick={() => [this.show_modal(3, data.value), this.get_single_project(data.value), this.get_assigh_user(data.value)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-eye size_img"></i></a> */}
                                                                                <a onClick={() => [this.del_assinees(data.value,data.id), this.get_assigh_user( this.state.project_id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                    data-overlaySpeed="200" data-overlayColor="#36404a" ><i style={{ "margin-left": "10px" }} className="fa fa-trash size_img"></i></a>
                                                                            </td>
                                                                        </tr>

                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (<div></div>)
                                    }
                                    <div className="custom-modal-text_2">
                                        <form method="" action="">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.assign_resources3}</label></div>
                                                            <div className="col-md-9" >

                                                                {
                                                                    this.state.assign_users != '' ?
                                                                        (<Select
                                                                            // closeMenuOnSelect={false}
                                                                            // components={animatedComponents}
                                                                            // defaultValue={[this.state.assign_users[0]]}
                                                                            // defaultValue={this.state.assign_users}
                                                                            // isClearable={true}
                                                                            // defaultValue={this.state.assign_users}
                                                                            // isMulti
                                                                            name="colors"
                                                                            options={this.state.all_tenants}
                                                                            // options={colourOptions}
                                                                            onChange={(e) => this.form_data2(e, 4)}
                                                                            // className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                        />) :
                                                                        (<div>
                                                                            <Select
                                                                                // isMulti
                                                                                options={this.state.all_tenants}
                                                                                onChange={(e) => this.form_data2(e, 4)}

                                                                            />
                                                                        </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.date3}</label></div>
                                                            <div className="col-md-9" ><input id={0} onChange={(e) => this.form_data2(e.target.value, 1)} type="date" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.plan_date} id="field-3" placeholder="Mr" /></div>
                                                            {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.start_time3}</label></div>
                                                            <div className="col-md-9" ><input id={12} onChange={(e) => this.form_data2(e.target.value, 2)} type="time" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.start_time} id="field-3" placeholder="Mr" /></div>
                                                            {/* <div className="form-control line_inp" >ssdfsd</div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.end_time3}</label></div>
                                                            <div className="col-md-9" ><input onChange={(e) => this.form_data2(e.target.value, 3)} type="time" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.end_time} id="field-3" placeholder="Mr" /></div>
                                                            {/* <div className="form-control line_inp" >ssdfsd</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button onClick={() => [this.addign_uesr()]} type="button" className="btn core waves-effect waves-light">{this.state.assign_user_btn3}</button>
                                            </div>
                                        </form>
                                    </div>

                                </div  >

                            </div>

                            <footer className="footer text-right">
                                &copy; 2020. All rights reserved.
          </footer>

                        </div>
                    </div>

                </div>

            </div>
        );
    }


}

export default App;

