import React from 'react';
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png'
import { connect } from 'react-redux';
import URL from './URL'
import { BrowserRouter as Router, Route, Switch, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";
import $ from "jquery";
import Cookies from 'js-cookie'
import Select from 'react-select';
import { Component } from "react";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            remember_me: true,
            admin_type: '',
            tenant_id: '',
            check:0

            
        }
    }
    componentDidMount = () => {
        // this.get_language_text()
        this.get_language_datbase()

        if(localStorage.getItem('data')==null){
            return false
        }
        // alert(localStorage.getItem('data')==null)
        // get_language_text('eng')

        this.auto_login()
        this.get_language_datbase()

    }
    form_data = (v, id) => {

        switch (id) {
            case 1:
                this.state.email = v
                this.setState({
                    email: v
                })
                break;
            case 2:
                this.state.password = v
                this.setState({
                    password: v
                })
                break;
            case 3:
                if (v) {
                    if (v == true) {

                        this.state.remember_me = false
                        this.setState({
                            remember_me: false
                        })
                    } else {
                        this.state.remember_me = true
                        this.setState({
                            remember_me: true
                        })
                    }

                } else {
                    this.state.remember_me = true
                    this.setState({
                        remember_me: true
                    })
                }

                break;
        }
    }
    send_data = async () => {

        if (this.state.check==0) {
            alert('Select any language')
            return false;
        }
        await fetch(URL.url + 'admin_login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": this.state.email,
                "password": this.state.password
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (resjson.error == "Unauthorised") {
                    alert('Email or Password are incorrect')
                } else {
                    this.state.admin_type = resjson.success.admin_type
                    this.setState({
                        admin_type: resjson.success.admin_type,
                        tenant_id: resjson.success.id,
                    })
                    if (this.state.remember_me) {
                        Cookies.set('email', this.state.email, { expires: 7 });
                        Cookies.set('pass', this.state.password, { expires: 7 });
                    }
                    Cookies.set('U_login_date', resjson.success.last, { expires: 7 });
                    Cookies.set('U_name', resjson.success.username, { expires: 7 });
                    $('.navigate').trigger('click');
                }
            })
            .catch(err => {

            })


    }
    auto_login = async () => {
        await fetch(URL.url + 'admin_login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": Cookies.get('email'),
                "password": Cookies.get('pass')
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (resjson.error == "Unauthorised") {
                    alert('Email or Password are incorrect')
                } else {
                    this.state.admin_type = resjson.success.admin_type
                    // this.get_language_text()
                    this.setState({
                        admin_type: resjson.success.admin_type,
                        tenant_id: resjson.success.id,
                    })

                    Cookies.set('email', Cookies.get('email'), { expires: 7 });
                    Cookies.set('pass', Cookies.get('pass'), { expires: 7 });
                    Cookies.set('U_login_date', resjson.success.last, { expires: 7 });
                    $('.navigate').trigger('click');
                }
            })
            .catch(err => {

            })


    }
    get_language_text = async (e) => {
        this.setState({check:1})
        await fetch(URL.url + 'languageWeb', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "languageSelect": e['value'],
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                // alert("adasd")
                localStorage.setItem('data', JSON.stringify(resjson));
                Cookies.set('lang', "");


            })
            .catch(err => {

            })
    }

    get_language_datbase = async () => {
        await fetch(URL.url + 'get_language', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "type":"web"
            }),
            // body: JSON.stringify({
            //     "languageSelect": Cookies.get('languageSelect'),
            // }),
        })

            .then(res => res.json())
            .then((resjson) => {
                let array = []
                for (let i = 0; i < resjson.length; i++) {
                    let temp = { value: resjson[i].value, label: resjson[i].title }
                    array.push(temp,)

                }
                // alert(resjson[0].value)
                 this.get_language_text(array[0])

                // this.get(resjson[0].value

                this.state.all_langu = array
                this.setState({
                    all_langu: this.state.all_langu

                })


            })
            .catch(err => {

            })
    }
    render() {
        return (
            <div>
                <div className="account-pages"></div>
                <div className="clearfix"></div>
                <div className="wrapper-page">
                    <div className="panel-heading">
                        <img src={logo} className="imgs" />
                    </div>
                    <div className="card-box">
                        <div className="p-20">
                            <form className="form-horizontal m-t-20" >
                                <div className="form-group ">
                                    <div className="col-10 login_top">
                                        <input onChange={(e) => this.form_data(e.target.value, 1)} className="form-control in_setting" type="text" required="" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-10">
                                        <input onChange={(e) => this.form_data(e.target.value, 2)} className="form-control in_setting" type="password" required="" placeholder="Password" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col-10" style={{ marginLeft: '33px' }}>
                                        <div>
                                            <Select onChange={(e) => this.get_language_text(e)} options={this.state.all_langu} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="col-12">
                                        <div className="checkbox checkbox-primary abc">
                                            <input onChange={() => this.form_data(this.state.remember_me, 3)} id="checkbox-signup" type="checkbox" className='remember' checked={this.state.remember_me} />
                                            <label for="checkbox-signup">
                                                Remember me
                                        </label>
                                            {/* <a href="page-recoverpw.html" className="text-dark forget " >Forgot
                                        password?</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center m-t-40">
                                    <div className="col-7">
                                        <Link>
                                            <button onClick={() => this.send_data()} className="btn btn-pink btn-block text-uppercase waves-effect waves-light "
                                            >LogIn
                                      </button>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Link to={{
                        pathname: "dashbord",
                        state: [{ id: this.state.admin_type, tenant_id: this.state.tenant_id }]
                    }}>
                        <button className=" navigate d-none " >
                            LogIn
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        myG_mainData: state.G_mainData,
        myG_RnkData: state.G_RnkData,
        myG_AcrData: state.G_AcrData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeGMainData: (data) => { dispatch({ type: 'CHANGE_G_MAIN_DATA', payload: data }) },
        changeGUniData: (data) => { dispatch({ type: 'CHANGE_UNI_DATA', payload: data }) },
        changeGRnkData: (data) => { dispatch({ type: 'CHANGE_RNK_DATA', payload: data }) },
        changeG_P_Data: (data) => { dispatch({ type: 'CHANGE_P_Flag', payload: data }) },
        changeG_D_Data: (data) => { dispatch({ type: 'CHANGE_D_Flag', payload: data }) },
        changeGAcrData: (data) => { dispatch({ type: 'CHANGE_ACR_DATA', payload: data }) }


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);