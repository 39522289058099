import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png';
import avatar_1 from '../assets/images/avatar-1.jpg';
import main from '../assets/images/main.png';
import Header from '../components/header';
import $ from "jquery";
import URL from './URL';

import Cookies from 'js-cookie'


import { Component } from "react";

class dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            inactive: 0,
            tanants: 0,
            
            last_login_title: 'Last Login',
            last_login: 'LOGIN',
            active_users: 'USERS',
            active_users_title: 'Active Users',
            inactive_users: 'USERS',
            inactive_users_title: 'Inactive Users',
            last_login: 'LOGIN',
            logout: 'Logout',
            tenants: 'Tenants',
            welcome: 'Welcome',
            company_management: 'Company Management',
            dashboard: 'Dashboard',
            project_management: 'Project Management',
            tenants_configration: 'Tenants Configration',
            user_management: 'User Management',
        }
    }
    componentDidMount = () => {
        let data = JSON.parse(localStorage.getItem('data'))['home']

        // alert(JSON.parse(localStorage.getItem('data'))['login']['email_placeholder'])
        this.setState({
            title: data['title'],
            welcome: data['welcome'],
            last_login_title: data['last_login_title'],
            last_login: data['last_login'],
            active_users: data['active_users'],
            active_users_title: data['active_users_title'],
            inactive_users: data['inactive_users'],
            inactive_users_title: data['inactive_users_title'],
            last_login: data['last_login'],
            logout: data['logout'],
            tenants: data['tenants'],
            welcome: data['welcome'],
            tenants_title: data['tenants_title'],
        })

        let data2 = JSON.parse(localStorage.getItem('data'))['sideBarList']

        // alert(JSON.parse(localStorage.getItem('data'))['login']['email_placeholder'])
        this.setState({
            dashboard: data2['dashboard'],
            tenants_configration: data2['tenants_configration'],
            company_management: data2['company_management'],
            user_management: data2['user_management'],
            project_management: data2['project_management'],
            
        })
        // alert(this.state.last_login_title)

        this.state.admin_type = this.props.location.state[0].id
        this.setState({
            admin_type: this.props.location.state[0].id
        })

        this.user_count()
        this.get_tenants()
        var a = Cookies.get('lang')
        // alert('akhdsg')
    }

    user_count = async () => {
        await fetch(URL.url + 'all_user_status', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    active: resjson['active'],
                    inactive: resjson['inactive'],
                })
            })
            .catch(err => {
            })
    }
    get_tenants = async () => {
        await fetch(URL.url + 'tenant_all', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                this.setState({
                    tanants: resjson.length
                })
            })
            .catch(err => {

            })
    }
    render() {
        return (
            <div>
                <div id="wrapper">
                    <Header></Header>
                    <div className="left side-menu">
                        <div className="sidebar-inner slimscrollleft">
                            <div id="sidebar-menu">
                                <ul>
                                    <li className="has_sub">
                                        <Link className='active' to={{
                                            pathname: "dashbord",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="ti-dashboard"></i> <span>{this.state.dashboard} </span> </Link>
                                        {
                                            this.props.location.state[0].id != 1 ? (<Link to={{
                                                pathname: "tenant",
                                                state: [{ id: '', tenant_id: '' }]
                                            }}><i className="md md-settings"></i> <span>{this.state.tenants_configration}</span> </Link>)
                                                : (<div></div>)
                                        }
                                        <Link to={{
                                            pathname: "company",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-business"></i> <span>{this.state.company_management}</span> </Link>
                                        <Link to={{
                                            pathname: "user",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-account-box"></i> <span>{this.state.user_management} </span> </Link>
                                        <Link to={{
                                            pathname: "project",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-list"></i> <span>{this.state.project_management}</span> </Link>
                                    </li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-3">
                                        <div className="card-box widget-box-1">
                                            <i className="fa fa-info-circle text-muted pull-right inform" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Last Login"></i>
                                            <h4 className="font-18">{this.state.last_login_title}</h4>
                                            <h2 className="text-primary text-center numbr_colr"><span data-plugin="">
                                                {Cookies.get('U_login_date')}</span></h2>
                                            <h6 className="text-muted box_text">{this.state.last_login}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-3">
                                        <div className="card-box widget-box-1">
                                            <i className="fa fa-info-circle text-muted pull-right inform" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Active users"></i>
                                    <h4 className="font-18">{this.state.active_users_title}</h4>
                                            <h2 className="text-pink text-center"><span data-plugin="counterup">{this.state.active}</span></h2>
                                    <h6 className="text-muted box_text"> {this.state.active_users}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-3">
                                        <div className="card-box widget-box-1">
                                            <i className="fa fa-info-circle text-muted pull-right inform" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Users"></i>
                                    <h4 className="font-18">{this.state.inactive_users_title}</h4>
                                            <h2 className="text-success text-center"><span data-plugin="counterup">{this.state.inactive}</span></h2>
                                    <h6 className="text-muted box_text">{this.state.inactive_users}</h6 >
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-xl-3">
                                        <div className="card-box widget-box-1">
                                            <i className="fa fa-info-circle text-muted pull-right inform" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Totel Tenants"></i>
                                    <h4 className="font-18">{this.state.tenants_title}</h4>
                                            <h2 className="text-warning text-center"><span data-plugin="counterup">{this.state.tanants}</span></h2>
                                    <h6 className="text-muted box_text"> {this.state.tenants}</h6 >
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <footer className="footer text-right">
                            &copy; 2020. All rights reserved.
            </footer>

                    </div>

                </div>
                <script src="assets/js/popper.min.js"></script>
                <script src="assets/js/bootstrap.min.js"></script>
                <script src="assets/js/detect.js"></script>
                <script src="assets/js/fastclick.js"></script>
                <script src="assets/js/jquery.slimscroll.js"></script>
                <script src="assets/js/jquery.blockUI.js"></script>
                <script src="assets/js/waves.js"></script>
                <script src="assets/js/wow.min.js"></script>
                <script src="assets/js/jquery.nicescroll.js"></script>
                <script src="assets/js/jquery.scrollTo.min.js"></script>
                <script src="../plugins/peity/jquery.peity.min.js"></script>
                <script src="../plugins/waypoints/lib/jquery.waypoints.min.js"></script>
                <script src="../plugins/counterup/jquery.counterup.min.js"></script>
                <script src="../plugins/morris/morris.min.js"></script>
                <script src="../plugins/raphael/raphael-min.js"></script>
                <script src="../plugins/jquery-knob/jquery.knob.js"></script>
                <script src="assets/pages/jquery.dashboard.js"></script>
                <script src="assets/js/jquery.core.js"></script>
                <script src="assets/js/jquery.app.js"></script>
                <script type="text/javascript">
                </script>
            </div>
        );
    }


}

export default dashboard;


