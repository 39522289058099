import React from 'react';
import Switch from "react-switch";
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png'
import avatar_1 from '../assets/images/avatar-1.jpg';
import main from '../assets/images/main.png';
import Header from '../components/header';
import URL from './URL';
import Select from 'react-select';
import axios, { post } from 'axios';

import $ from "jquery";

import { BrowserRouter as Router, Route, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";


import { Component } from "react";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_users: [{ id: "", sol: "", name: "", l_name: "", status: '' }],
            checked: false,
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
            salutation: '',
            first_n: '',
            last_n: '',
            email: '',
            user_id: '',
            tanant: '',
            all_tenants: '',
            file: '',
            file_name: '',
            user_admin: false,
            admin_type: '',


            title4: 'Welcome to Admin',
            user_management4: 'User Managment',
            add_new_user_btn4: 'Add New User',
            import_user_btn4: 'Import User',
            table_sol4: 'Sol',
            table_first_name4: 'First Name',
            table_last_name4: 'Last Name',
            table_email4: 'Email',
            table_status4: 'Status',
            table_action4: 'Action',
            table_inActive4: 'InActive',

            add_tenant_title5: 'Edit User',
            salotation_title5: 'Salotation',
            first_name_title5: 'First Name',
            last_name_title5: 'Last Name',
            edit_user_btn5: 'Edit User',

            
            add_tenant_title6: 'Add Tenant',
            tenant_title6: 'Tenant',
            tenant_placeholder6: 'Select tenant',
            salotation_title6: 'Salotation',
            salotation_placeholder6: 'Mr',
            first_name_title6: 'First Name',
            first_name_placeholder6: 'First Name',
            last_name_title6: 'MLast Namer',
            last_name_placeholder6: 'Last Name',
            email_title6: 'Email',
            email_placeholder6: 'Email',
            save_user_btn6: 'Save User',

            import_title7: 'Import User',
            chose_file_btn7: 'Choose File',
            download_btn7: 'Download',
            import_btn7: 'Import',

            company_management: 'Company Management',
            dashboard: 'Dashboard',
            project_management: 'Project Management',
            tenants_configration: 'Tenants Configration',
            user_management: 'User Management',
     
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)

    }
    componentDidMount = () => {
    


        console.log(34567898765432345678909876)
        console.log(this.props.location);
        console.log(this.props.location.state[0].id);
        
        // this.state.admin_type = this.props.location.state[0].id
        // this.setState({
        //     admin_type: this.props.location.state[0].id
        // })
        if (this.props.location.state[0].id == 1) {
            this.get_user_by_user_id(this.props.location.state[0].tenant_id)
        }
        else {
            this.get_user();
        }
        $('._overlay').hide();

    }
    show_modal = (id) => {
        this.get_tenants();

        $('._overlay').show();

        console.log(34545654);
        switch (id) {
            case 1:
                this.get_tenants();
                this.state.modal_1 = 'block';
                this.setState({
                    modal_1: 'block'
                })
                break;
            case 2:
                this.state.modal_2 = 'block';
                this.setState({
                    modal_2: 'block'
                })
                break;
            case 3:
                this.state.modal_3 = 'block';
                this.setState({
                    modal_3: 'block'
                })
                break;
        }
    }
    hide_modal = (id) => {
        $('._overlay').hide();
        switch (id) {
            case 1:
                this.state.modal_1 = 'none';
                this.setState({
                    modal_1: 'none'
                })
                break;
            case 2:
                this.state.modal_2 = 'none';
                this.setState({
                    modal_2: 'none'
                })
                break;
            case 3:
                this.state.modal_3 = 'none';
                this.setState({
                    modal_3: 'none'
                })
                break;
        }

    }
    hide_all_modal = (id) => {
        $('._overlay').hide();
        this.state.modal_1 = 'none';
        this.state.modal_2 = 'none';
        this.state.modal_3 = 'none';
        this.setState({
            modal_1: 'none',
            modal_2: 'none',
            modal_3: 'none',
        });

    }
    form_data = (v, id) => {

        switch (id) {
            case 1:
                this.state.salutation = v
                this.setState({
                    salutation: v
                })
                break;
            case 2:
                this.state.first_n = v
                this.setState({
                    first_n: v
                })
                break;
            case 3:
                this.state.last_n = v
                this.setState({
                    last_n: v
                })
                break;
            case 4:
                this.state.email = v
                this.setState({
                    email: v
                })
                break;
            case 5:
                console.log(v);
                this.state.tanant = v
                this.setState({
                    tanant: v
                })
                break;
            case 6:
                if (v == true) {

                    this.state.user_admin = false
                    this.setState({
                        user_admin: false
                    })
                    console.log('true  ' + this.state.user_admin)
                } else {
                    this.state.user_admin = true
                    this.setState({
                        user_admin: true
                    })
                    console.log('false  ' + this.state.user_admin)
                }
                break;

        }
    }
    send_data = async () => {
        if (this.state.salutation == '' || this.state.first_n == '' || this.state.last_n == '' || this.state.email == '' || this.state.tanant['value'] == '') {
            alert('Fill all the fielda')
            return false
        }
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // return re.test(email);
        if (!re.test(this.state.email)) {
            alert('Email is not correct')
            return false
        }
        let tenant_id = this.state.tanant['value']
        if(this.props.location.state[0].id ==1){
            tenant_id=this.props.location.state[0].tenant_id
        }                         
        await fetch(URL.url + 'register_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "sol": this.state.salutation,
                "name": this.state.first_n,
                "l_name": this.state.last_n,
                "email": this.state.email,
                'admin_id': tenant_id,
                "user_type":1,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (resjson['message'] == 'Email already exits') {
                    alert('Email already exits')
                    return false
                }
                this.hide_all_modal()
                if (this.props.location.state[0].id == 1) {
                    this.get_user_by_user_id(this.props.location.state[0].tenant_id)
                }
                else {
                    this.get_user();
                }
                alert('User register successfully')
                console.log(resjson.success.token)
            })
            .catch(err => {
                console.log('failed', err)
                
            })

    }
    get_tenants = async () => {

        await fetch(URL.url + 'user_admin_all', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                console.log(resjson)
                let array = []
                for (let i = 0; i < resjson.length; i++) {
                    let temp = { value: resjson[i].id, label: resjson[i].email }
                    array.push(temp,)

                }
                this.state.all_tenants = array
                this.setState({
                    all_tenants: this.state.all_tenants
                })
                console.log(array)


            })
            .catch(err => {
                console.log('failed', err)
                
            })


    }
    change_status = async (status, id) => {
        let a = true
        if (status == 1) {
            a = '0'
        } else {
            a = '1'
        }
        await fetch(URL.url + 'update_user_status', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "status": a,
                "id": id,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                if (this.props.location.state[0].id == 1) {
                    this.get_user_by_user_id(this.props.location.state[0].tenant_id)
                }
                else {
                    this.get_user();
                }

            })
            .catch(err => {
                console.log('failed', err)
                
            })
    }
    get_user = async () => {
        await fetch(URL.url + 'all_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(res => res.json())
            .then((resjson) => {
                console.log(resjson)
                this.setState({
                    all_users: resjson
                })
                let data4=JSON.parse(localStorage.getItem('data'))['userManagement']
                let data22 = JSON.parse(localStorage.getItem('data'))['sideBarList']
                this.setState({
                    dashboard: data22['dashboard'],
                    tenants_configration: data22['tenants_configration'],
                    company_management: data22['company_management'],
                    user_management: data22['user_management'],
                    project_management: data22['project_management'],

                    title4: data4['title'],
                    user_management4:  data4['user_management'],
                    add_new_user_btn4: data4['add_new_user_btn'],
                    import_user_btn4:  data4['import_user_btn'],
                    table_sol4:  data4['table_sol'],
                    table_first_name4:  data4['table_first_name'],
                    table_last_name4:  data4['table_last_name'],
                    table_email4:  data4['table_email'],
                    table_status4:  data4['table_status'],
                    table_action4:  data4['table_action'],
                    table_inActive4:  data4['table_inActive'],
                })
                let data5=JSON.parse(localStorage.getItem('data'))['editUser']
                this.setState({
                    add_tenant_title5: data5['add_tenant_title'],
                    salotation_title5:  data5['salotation_title'],
                    first_name_title5: data5['first_name_title'],
                    last_name_title5:  data5['last_name_title'],
                    edit_user_btn5:  data5['edit_user_btn'],
           
                })

                let data6=JSON.parse(localStorage.getItem('data'))['addNewUser']
                this.setState({
                    add_tenant_title6: data6['add_tenant_title'],
                    tenant_title6:  data6['tenant_title'],
                    tenant_placeholder6: data6['tenant_placeholder'],
                    salotation_title6:  data6['salotation_title'],
                    salotation_placeholder6:  data6['salotation_placeholder'],
                    first_name_title6:  data6['first_name_title'],
                    first_name_placeholder6:  data6['first_name_placeholder'],
                    last_name_title6:  data6['last_name_title'],
                    last_name_placeholder6:  data6['last_name_placeholder'],
                    email_title6:  data6['email_title'],
                    email_placeholder6:  data6['email_placeholder'],
                    save_user_btn6:  data6['save_user_btn'],
           
                })
                let data7=JSON.parse(localStorage.getItem('data'))['addImportUser']
                this.setState({
                    import_title7: data7['import_title'],
                    chose_file_btn7:  data7['chose_file_btn'],
                    download_btn7: data7['download_btn'],
                    import_btn7:  data7['import_btn'],
            
           
                })
                // let array = []
                // for (let i = 0; i < resjson.length; i++) {
                //     let temp = { value: resjson[i].id, label: resjson[i].username }
                //     array.push(temp,)

                // }
                // this.state.all_tenants = array
                // this.setState({
                //     all_tenants: this.state.all_tenants
                // })
                // console.log(array)
            })
            .catch(err => {
                console.log('failed', err)
                
            })
    }
    get_user_by_user_id = async (id) => {
        await fetch(URL.url + 'all_admin_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'user_admin_id':id}),
        })
            .then(res => res.json())
            .then((resjson) => {
                console.log(resjson)
                this.setState({
                    all_users: resjson
                })
                // let array = []
                // for (let i = 0; i < resjson.length; i++) {
                //     let temp = { value: resjson[i].id, label: resjson[i].username }
                //     array.push(temp,)

                // }
                // this.state.all_tenants = array
                // this.setState({
                //     all_tenants: this.state.all_tenants
                // })
                // console.log(array)
            })
            .catch(err => {
                console.log('failed', err)
                
            })
    }
    get_single_user = async (id) => {

        await fetch(URL.url + 'single_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'id': id
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                console.log(resjson[0].id)
                this.setState({
                    salutation: resjson[0].sol,
                    first_n: resjson[0].name,
                    last_n: resjson[0].l_name,
                    user_id: id
                })

            })
            .catch(err => {
                console.log('failed', err)
                
            })
    }
    update_user = async () => {

        await fetch(URL.url + 'update_user', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'id': this.state.user_id,
                "sol": this.state.salutation,
                "name": this.state.first_n,
                "l_name": this.state.last_n,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                // console.log(resjson[0].id)
                this.hide_all_modal();
                
                if (this.props.location.state[0].id == 1) {
                    this.get_user_by_user_id(this.props.location.state[0].tenant_id)
                }
                else {
                    this.get_user();
                }

            })
            .catch(err => {
                console.log('failed', err)
                
            })
    }
    import_user = async () => {


    }
    onFormSubmit(e) {
        e.preventDefault()
        this.fileUpload(this.state.file);
    }
    onChange(e) {
        this.setState({
            file_name: e.target.files[0].name
        })
        // console.log(e.target.files[0].name)
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                file: e.target.result
            })
        };
        reader.readAsDataURL(file);
    }
    fileUpload = async (image) => {

        var res = this.state.file.replace("data:application/octet-stream;base64,", "")
        console.log(this.state.file)
        await fetch(URL.url + 'import', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "file": res,
                "file_name": this.state.file_name,
            }),
        })
            .then(res => res.json())
            .then((resjson) => {
                // console.log(resjson[0].id)
                this.hide_all_modal();
                this.get_user();

            })
            .catch(err => {
                console.log('failed', err)
                
            })

    }
    render() {
        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]
        return (
            <div>
                <div onClick={() => this.hide_all_modal()} className='_overlay' style={{ width: '100%', height: '100%', 'background-color': '#343a409e', 'z-index': '1000', position: 'absolute' }} > </div>
                <div id="wrapper">
                    <Header></Header>
                    <div className="left side-menu">
                        <div className="sidebar-inner slimscrollleft">

                            <div id="sidebar-menu">
                                <ul>
                                    <li className="has_sub">
                                        <Link to={{
                                            pathname: "dashbord",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="ti-dashboard"></i> <span>{this.state.dashboard}</span> </Link>
                                        {
                                            this.props.location.state[0].id != 1 ? (<Link to={{
                                                pathname: "tenant",
                                                state: [{ id: '', tenant_id: '' }]
                                            }}><i className="md md-settings"></i> <span>{this.state.tenants_configration}</span> </Link>)
                                                : (<div></div>)
                                        }
                                        <Link to={{
                                            pathname: "company",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-business"></i> <span>{this.state.company_management}</span> </Link>
                                        <Link
                                            className='active'
                                            to={{
                                                pathname: "user",
                                                state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                            }}><i className="md-account-box"></i> <span>{this.state.user_management}</span> </Link>
                                        <Link to={{
                                            pathname: "project",
                                            state: [{ id: this.props.location.state[0].id, tenant_id: this.props.location.state[0].tenant_id }]
                                        }}><i className="md-list"></i> <span>{this.state.project_management} </span> </Link>
                                    </li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="content-page">
                        <div className="content">
                            <div className="row title_wid">
                                <div className="col-12 main">
                                    <h5 className="title">{this.state.user_management4}</h5>
                                    <a onClick={() => this.show_modal(1)} className="btn btn-primary waves-effect waves-light ad_user" data-animation="fadein" data-plugin="custommodal"
                                        data-overlaySpeed="200" data-overlayColor="#36404a">{this.state.add_new_user_btn4}</a>

                                    <a onClick={() => this.show_modal(2)} className="btn  waves-effect waves-light btn_text" data-animation="fadein" data-plugin="custommodal"
                                        data-overlaySpeed="200" data-overlayColor="#36404a">{this.state.import_user_btn4}</a>
                                </div>
                            </div>
                            <div className="container-fluid">
                                {
                                    this.state.all_users != [] ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-box table-responsive">
                                                    <table id="datatable" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th><i className="fa fa-cog size_img" /></th>
                                                                <th className="txt_color">{this.state.table_sol4}</th>
                                                                <th className="txt_color">{this.state.table_first_name4}</th>
                                                                <th className="txt_color">{this.state.table_last_name4}</th>
                                                                <th className="txt_color">{this.state.table_email4}</th>
                                                                <th className="txt_color">{this.state.table_status4}</th>
                                                                <th className="txt_color">{this.state.table_action4}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.all_users.map((data, index) => (
                                                                    <tr>
                                                                        <td className="td_color">
                                                                            <a onClick={() => [this.show_modal(3), this.get_single_user(data.id)]} className="size_img" data-animation="fadein" data-plugin="custommodal"
                                                                                data-overlaySpeed="200" data-overlayColor="#36404a" ><i className="fa fa-pencil size_img"></i></a>
                                                                        </td>
                                                                        <td className="td_color">{data.sol}</td>
                                                                        <td className="td_color">{data.name}</td>
                                                                        <td className="td_color">{data.l_name}</td>
                                                                        <td className="td_color">{data.email}</td>
                                                                        <td className="td_color"> {data.status == 1 ? (<span className="label label-table label-success">Active</span>) : (<span className="label label-table label-warning">InActive</span>)}</td>
                                                                        <td className="td_color">
                                                                            <Switch
                                                                                checked={parseInt(data.status)}
                                                                                onChange={() => this.change_status(data.status, data.id)}
                                                                                onColor="#81c868"
                                                                                onHandleColor="#fff"
                                                                                handleDiameter={30}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                height={20}
                                                                                width={48}
                                                                                className="react-switch"
                                                                                id="material-switch"
                                                                            />
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<div></div>)
                                }
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_1, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(1)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                            <h4 className="custom-modal-title tit-mod">{this.state.add_tenant_title6}</h4>
                                <div className="custom-modal-text">
                                    <form method="" action="">
                                        <div className="modal-body">
                                            {
                                                this.props.location.state[0].id != 1 ?
                                                    (<div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label for="field-3" className="control-label input_title">{this.state.tenant_title6}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <div>
                                                                    <Select onChange={(e) => this.form_data(e, 5)} options={this.state.all_tenants} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>) : (<div></div>)
                                            }


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.salotation_title6}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 1)} className="form-control line_inp" id="field-3" placeholder={this.state.salotation_placeholder6} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.first_name_title6}</label>
                                                        <input onChange={(e) => this.form_data(e.target.value, 2)} type="text" className="form-control line_inp" id="field-3" placeholder={this.state.first_name_placeholder6} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.last_name_title6}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 3)} className="form-control line_inp" id="field-3" placeholder={this.state.last_name_placeholder6} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.email_title6}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 4)} className="form-control line_inp" id="field-3" placeholder={this.state.email_placeholder6} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="form-group row">
                                                <div className="col-12" style={{}}>
                                                    <div style={{ 'margin-right': '19%', 'margin-left': '12PX' }} className="checkbox select_tpye">
                                                        <input onChange={() => this.form_data(this.state.user_admin, 6)} id="checkbox17" type="checkbox" />
                                                        <label style={{ color: '#9a9a9a' }} className="control-label input_title" for="checkbox17">
                                                            User Admin ?
                                                    </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={()=> this.send_data()}  type="button" className="btn core waves-effect waves-light">{this.state.save_user_btn6}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_2, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(2)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                        <h4 className="custom-modal-title tit-mod">{ this.state.import_title7}</h4>
                                <div className="custom-modal-text_2">
                                    <div className="modal-body">
                                        <form onSubmit={this.onFormSubmit}>
                                            <div className="form-group m-b-0">
                                                <input className="filestyle" data-buttonname="btn-primary" type="file" onChange={this.onChange} />
                                            </div>
                                            <div className="modal-footer">
                                            <a href="http://ascensify.golbalsmartech.co.uk/api/simples.csv" type="submit" className="btn btn-info waves-effect waves-light">{ this.state.download_btn7}</a>
                                                <button onChange={this.fileUpload} type="submit" className="btn btn-info waves-effect waves-light">{ this.state.import_btn7}</button>
                                                
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                            <div id="view-modal" style={{ 'top': '0%', display: this.state.modal_3, position: 'fixed', left: '30%', 'z-index': '1000', 'background-color': '#fff', width: '45%', margin: '20px auto' }} >
                                <button type="button" onClick={() => this.hide_modal(3)} className="close" onclick="Custombox.close();">
                                    <span>&times;</span><span className="sr-only">Close</span>
                                </button>
                                        <h4 className="custom-modal-title tit-mod">{this.state.add_tenant_title5}</h4>
                                <div className="custom-modal-text">
                                    <form method="" action="">
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.salotation_title5}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 1)} className="form-control line_inp" value={this.state.salutation} id="field-3" placeholder="Mr" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.first_name_title5}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 2)} className="form-control line_inp" value={this.state.first_n} id="field-3" placeholder="First Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="field-3" className="control-label input_title">{this.state.last_name_title5}</label>
                                                        <input type="text" onChange={(e) => this.form_data(e.target.value, 3)} className="form-control line_inp" value={this.state.last_n} id="field-3" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={() => this.update_user()} type="button" className="btn core waves-effect waves-light">{this.state.edit_user_btn5}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <footer className="footer text-right">
                                &copy; 2020. All rights reserved.
          </footer>

                        </div>
                    </div>

                </div>

            </div>
        );
    }


}

export default App;

