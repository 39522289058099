import React from 'react';
import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';
import logo from '../assets/images/login.png'
import avatar_1 from '../assets/images/avatar_2.png';
import main from '../assets/images/main.png';
import Cookies from 'js-cookie'

import $ from "jquery";

import { BrowserRouter as Router, Route, Switch, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";


import { Component } from "react";

class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Welcome to Admin',
            welcome: 'Welcome',
            logout: 'Logout',
        }
    }
    componentDidMount = () => {
        let data = JSON.parse(localStorage.getItem('data'))['home']

        // console.log(JSON.parse(localStorage.getItem('data'))['login']['email_placeholder'])
        this.setState({
            title: data['title'],
            welcome: data['welcome'],
            logout: data['logout'],

        })
    }
    logout = () => {
        Cookies.remove('email')
        Cookies.remove('pass')
    }

    render() {
        return (
            <div className="topbar">

                <div className="topbar-left">
                    <div className="text-center">
                        <a className="logo">
                            <span><img src={main} height="20" style={{ height: 60 }} className="" /></span>
                        </a>
                    </div>
                </div>

                <nav className="navbar-custom">

                    <ul className="list-inline float-right mb-0">


                        <li className="list-inline-item dropdown notification-list">
                            <a className="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" role="button"
                                aria-haspopup="false" aria-expanded="false">
                                <img src={avatar_1} alt="user" className="rounded-circle" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right profile-dropdown " aria-labelledby="Preview">

                                <div className="dropdown-item noti-title">
                                    <h5 className="text-overflow"><small>{this.state.welcome} {Cookies.get('U_name')}</small> </h5>
                                </div>
                                <Link to={{ pathname: "/" }}>
                                    <a onClick={() => this.logout()} className="dropdown-item notify-item">
                                        <i className="md md-settings-power"></i> <span>{this.state.logout}</span>
                                    </a>
                                </Link>
                            </div>
                        </li>
                    </ul>

                    <ul className="list-inline menu-left mb-0">
                        <li className="float-left">
                            {/* <button className="button-menu-mobile open-left waves-light waves-effect">
                                <i className="dripicons-menu"></i>
                            </button> */}
                        </li>
                        <h3 className="head">{this.state.title}</h3>
                    </ul>

                </nav>

            </div>

        );
    }


}

export default header;

