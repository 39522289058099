import React from 'react';

import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.css';
import '../assets/css/style.css';


import Select from 'react-select';


import $ from "jquery";




import { BrowserRouter as Router, Route, Switch, Redirect, Link, HashRouter, BrowserRouter } from "react-router-dom";

import { Component } from "react";

class Schadual_form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Efile: '',
        }
    }
 

    render() {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.assign_resources3}</label></div>
                            <div className="col-md-9" >

                                {
                                    this.state.assign_users != '' ?
                                        (<Select
                                            // closeMenuOnSelect={false}
                                            // components={animatedComponents}
                                            // defaultValue={[this.state.assign_users[0]]}
                                            // defaultValue={this.state.assign_users}
                                            // isClearable={true}
                                            // defaultValue={this.state.assign_users}
                                            // isMulti
                                            name="colors"
                                            options={this.state.all_tenants}
                                            // options={colourOptions}
                                            onChange={(e) => this.form_data2(e, 4)}
                                            // className="basic-multi-select"
                                            classNamePrefix="select"
                                        />) :
                                        (<div>
                                            <Select
                                                // isMulti
                                                options={this.state.all_tenants}
                                                onChange={(e) => this.form_data2(e, 4)}

                                            />
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.date3}</label></div>
                            <div className="col-md-9" ><input id={0} onChange={(e) => this.form_data2(e.target.value, 1)} type="date" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.plan_date} id="field-3" placeholder="Mr" /></div>
                            {/* <div className="form-control line_inp" >ssdfsd</div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.start_time3}</label></div>
                            <div className="col-md-9" ><input id={12} onChange={(e) => this.form_data2(e.target.value, 2)} type="time" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.start_time} id="field-3" placeholder="Mr" /></div>
                            {/* <div className="form-control line_inp" >ssdfsd</div> */}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <div className="col-md-3 pt-1 " ><label for="field-3" className="control-label input_title">{this.state.end_time3}</label></div>
                            <div className="col-md-9" ><input onChange={(e) => this.form_data2(e.target.value, 3)} type="time" style={{ backgroundColor: '#fff' }} className="form-control line_inp" value={this.state.end_time} id="field-3" placeholder="Mr" /></div>
                            {/* <div className="form-control line_inp" >ssdfsd</div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (Schadual_form);

